import './errorsoverview.css';
import { ValidationFailureResponse } from '../../../api/petcloudapi/api';
import Retractable from '../../../elements/retractable/Retractable';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconError } from '../../../../assets/icon/warning.svg';
import { ReactComponent as IconInfo } from '../../../../assets/icon/information.svg';
import { ReactComponent as IconFilter } from '../../../../assets/icon/filter.svg';
import imgDone from '../../../../assets/img/done.svg';
import { EmptyState } from '../../../elements/emptystate/EmptyState';
import Button from '../../../elements/button/Button';
import { useNavigate } from 'react-router-dom';

interface ErrorsOverviewProps {
  errors?: ValidationFailureResponse[] | null;
  showMessageInHead?: boolean;
  productId?: string;
  isVariant?: boolean;
  onErrorFilter?: (errorPropertyName: string) => void;
}

const ErrorsOverview: React.FC<ErrorsOverviewProps> = ({
  errors,
  showMessageInHead,
  productId,
  isVariant,
  onErrorFilter,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.errorsOverview',
  });
  const link = useNavigate();

  if (errors) {
    return (
      <div className={'errorsOverview'}>
        {errors.map((error, i) => {
          return (
            <div key={i} className={'errorsOverview-retractable'}>
              <Retractable
                title={error.propertyName}
                head={
                  <div className={'errorsOverview-retractable-head'}>
                    {showMessageInHead ? (
                      <div
                        className={'errorsOverview-retractable-head-message'}
                      >
                        {error.errorMessage}
                      </div>
                    ) : (
                      <span></span>
                    )}
                    {error.severity === 'Info' ? (
                      <IconInfo
                        className={'errorsOverview-retractable-head-icon'}
                        fill={'var(--color-blue)'}
                      />
                    ) : (
                      <IconError
                        className={'errorsOverview-retractable-head-icon'}
                        fill={
                          error.severity === 'Error'
                            ? 'var(--color-danger)'
                            : 'var(--color-yellow)'
                        }
                      />
                    )}
                  </div>
                }
              >
                <div className={'errorsOverview-error'}>
                  <div
                    className={
                      'errorsOverview-error-element errorsOverview-error-message'
                    }
                  >
                    {error.errorMessage}
                  </div>
                  <div
                    className={
                      'errorsOverview-error-element errorsOverview-error-devInfo'
                    }
                  >
                    {`${error.errorCode} | ${t('attemptedValue')}: ${
                      error.attemptedValue
                    }`}
                  </div>
                  {error.errorCode ? (
                    <div
                      className={
                        'errorsOverview-error-element errorsOverview-error-code global-monospaced-contrast'
                      }
                    >
                      {error.errorCode}
                    </div>
                  ) : null}
                  <div className={'errorsOverview-error-element'}>
                    {error.customState}
                  </div>
                  <div className={'errorsOverview-error-element'}>
                    {error.formattedMessagePlaceholderValues}
                  </div>
                  {onErrorFilter ? (
                    <Button
                      type={'icon'}
                      width={'minimal'}
                      look={'secondary'}
                      action={() => onErrorFilter(error.propertyName)}
                      helperCSSClass={'errorsOverview-error-button'}
                    >
                      <IconFilter className={'button-icon-secondary'} />
                    </Button>
                  ) : null}
                </div>
              </Retractable>
            </div>
          );
        })}
        {productId ? (
          <div className={'global-cardActions'}>
            <Button
              cta={t('goToProduct')}
              look={'secondary'}
              width={'minimal'}
              action={() =>
                link(`/products/${isVariant ? 'variant/' : ''}${productId}`)
              }
            />
          </div>
        ) : null}
      </div>
    );
  } else {
    return <EmptyState message={t('noErrors')} img={imgDone} size={140} />;
  }
};

export default ErrorsOverview;
