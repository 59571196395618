import { AssetContext, AssetResponse, AssetType } from '../api/petcloudapi/api';
import { useErrorHandler } from '../contexts/errorhandler/ErrorHandler';
import { usePetCloudApi } from '../api/PetCloudApi';

type AssetUploadOptions = {
  assetContext?: AssetContext;
  assetType?: AssetType;
  assetFolderId?: string;
};

const useAssetUpload = () => {
  const api = usePetCloudApi();
  const assetApi = api.assetsApi();
  const errorHandler = useErrorHandler();

  const uploadAsset = (
    file: File,
    opts?: AssetUploadOptions
  ): Promise<AssetResponse> => {
    return new Promise((resolve, reject) => {
      const type = () => {
        const splits = file.type.split('/');
        if (splits.includes('pdf')) {
          return 'Document';
        } else if (splits.includes('image')) {
          return 'Image';
        } else if (splits.includes('mp4')) {
          return 'Video';
        } else {
          return 'Unknown';
        }
      };

      assetApi
        .assetsCreateAsset(
          undefined,
          file,
          opts?.assetContext,
          opts?.assetType ?? type(),
          opts?.assetFolderId
        )
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          reject('error');
        });
    });
  };

  return { uploadAsset };
};

export default useAssetUpload;
