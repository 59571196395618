import './orderlist.css';
import List from '../list/List';
import { displayStringHighlighted } from '../../elements/productsearch/ProductSearch';
import MinifiedOrder, { CustomerAttributes } from '../../types/MinifiedOrder';
import { useCallback, useEffect, useState } from 'react';
import {
  OrderResponse,
  SimpleOrderResponse,
  UnfulfilledOrdersResponse,
  WarehouseResponse,
} from '../../api/petcloudapi/api';
import _ from 'lodash';
import PaymentMethod from '../../sections/order/payment/paymentmethod/PaymentMethod';
import { useTranslation } from 'react-i18next';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { useUser } from '../../contexts/auth/User';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { ReactComponent as IconLinked } from '../../../assets/icon/linked.svg';
import { ReactComponent as IconWarning } from '../../../assets/icon/warning.svg';
import { ReactComponent as IconGuest } from '../../../assets/icon/guest.svg';
import { ReactComponent as IconCoin } from '../../../assets/icon/loyalty_coin.svg';
import { LoadingContainer } from '../../elements/loading/Loading';
import useDateTools from '../../hooks/useDateTools';
import { Tab, TabConfig, Tabs } from '../../elements/card/Card';
import UnfulfilledOrders from './unfulfilledorders/UnfulfilledOrders';
import useListRenderObjects from '../../hooks/list/useListRenderObjects';
import Hoverable from '../../elements/hoverable/Hoverable';
import useAnimalSpeciesListFilter from '../list/listcontrols/hooks/useAnimalSpeciesListFilter';
import useListControlsSearch from '../list/listcontrols/hooks/useListControlsSearch';
import useNumberFormat from '../../hooks/useNumberFormat';
import { HintBox } from '../../elements/hint/Hint';
import useDateRangeListFilter from '../list/listcontrols/hooks/useDateRangeListFilter';
import useLimitListFilter from '../list/listcontrols/hooks/useLimitListFilter';
import useManufacturerListFilter from '../list/listcontrols/hooks/useManufacturerListFilter';

interface OrderListProps {
  finishedLoadingCallback?: () => void;
  customerId?: string;
  ordersArray?: SimpleOrderResponse[];
  unfulfilledOrdersArray?: UnfulfilledOrdersResponse[];
  height?: number;
  hideUnfulfilledOrders?: boolean;
}

const OrderList: React.FC<OrderListProps> = ({
  finishedLoadingCallback,
  customerId,
  ordersArray,
  unfulfilledOrdersArray,
  height,
  hideUnfulfilledOrders,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.orders',
  });
  const api = usePetCloudApi();
  const ordersApi = api.ordersApi();
  const dashboardsApi = api.dashboardsApi();
  const errorHandler = useErrorHandler();
  const { user } = useUser();
  const { dateToCSharpDateTimeOffset } = useDateTools();
  const { renderManufacturer } = useListRenderObjects();
  const { listControlSearch, query, currentItems, setOriginalItems } =
    useListControlsSearch(t('search'), null, [
      'customerName',
      'orderNumber',
      'fiegeSalesOrderNumber',
      'total',
      'orderState.value',
      'transactionState.value',
      'manufacturer.companyName',
      'joinedSplitOrderNumbers',
    ]);
  const { renderCurrency } = useNumberFormat();

  const { manufacturerFilter, selectedManufacturerId } =
    useManufacturerListFilter({
      noAllManufacturers: true,
    });
  const { selectedDateRange, dateRangeOptionFilter, dateRangeFilter } =
    useDateRangeListFilter();
  const { selectedLimit, limitFilter } = useLimitListFilter({
    localStorageLimitSettingPrefix: 'orderList',
  });

  const [unfulfilledOrders, setUnfulfilledOrders] = useState<
    UnfulfilledOrdersResponse[] | null
  >(unfulfilledOrdersArray ?? null);

  useEffect(() => {
    const range = selectedDateRange
      ? [
          dateToCSharpDateTimeOffset(new Date(selectedDateRange[0]), true),
          dateToCSharpDateTimeOffset(new Date(selectedDateRange[1])),
        ]
      : undefined;
    console.log(range);
    getOrders(
      range ? range[0] : undefined,
      range ? range[1] : undefined,
      selectedManufacturerId,
      selectedLimit
    );
  }, [selectedDateRange, selectedLimit, selectedManufacturerId]);

  useEffect(() => {
    if (!unfulfilledOrders && !hideUnfulfilledOrders) {
      getUnfulfilledOrders();
    }
  }, []);

  const getUnfulfilledOrders = () => {
    dashboardsApi
      .dashboardsGetUnfulfilledOrders()
      .then((response) => {
        console.log(response);
        setUnfulfilledOrders(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const hasWarning = (
    orderState: string | null | undefined,
    shippingState: string | null | undefined,
    transactionState: string | null | undefined
  ) => {
    if (orderState && shippingState) {
      if (['Open', 'InProgress'].includes(orderState)) {
        if (['Returned', 'ReturnedPartially'].includes(shippingState)) {
          return true;
        }
      }
    }
    if (shippingState && transactionState)
      if (shippingState === 'Cancelled') {
        if (
          ['Paid', 'Authorized', 'RefundedPartially'].includes(transactionState)
        ) {
          return true;
        }
      }
    return false;
  };

  const getOrders = (
    from?: string,
    to?: string,
    manufacturerId?: string,
    limit?: number
  ) => {
    setOriginalItems(null);
    if (!ordersArray) {
      ordersApi
        .ordersGetSimpleOrdersList(customerId, from, to, limit, manufacturerId)
        .then((response) => {
          console.log(response);
          minifyAndSetOrders(response.data);
          if (finishedLoadingCallback) {
            finishedLoadingCallback();
          }
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
        });
    } else {
      minifyAndSetOrders(ordersArray);
    }
  };

  const minifyAndSetOrders = (orderResponses: SimpleOrderResponse[]) => {
    const minifiedOrders = orderResponses.map((order): MinifiedOrder => {
      const orderState = order.currentOrderStateTechnicalName;
      const shippingState = order.currentShippingStateTechnicalName;
      const transactionState = order.currentTransactionStateTechnicalName;
      return {
        id: order.id,
        customerName: order.customer
          ? `${order.customer.firstName} ${order.customer.lastName}`
          : undefined,
        customer: order.customer,
        customerAttributes: {
          isGuest: order.customer.isGuest,
          isLoyaltyOptIn: order.customer.attributes?.LoyaltyOptIn ?? false,
        },
        orderNumber: order.orderNumber,
        joinedSplitOrderNumbers: order.splitOrders
          ?.map((s) => s.orderNumber)
          .join(),
        fiegeSalesOrderNumber: order.fiegeSalesOrderNumber?.toString(),
        total: renderCurrency(order.amountTotal),
        orderState: orderState,
        shippingState: shippingState,
        transactionState: transactionState,
        initialPaymentMethodBrand: order.initialPaymentMethodBrand,
        orderDateTime: order.orderDateTime,
        manufacturer: order.manufacturer,
        warehouse: order.warehouse,
        warning: hasWarning(orderState, shippingState, transactionState),
        originOrderId: order.originOrderId,
        splitOrders: order.splitOrders,
      };
    });
    const sortedByOrderNumber = _.orderBy(minifiedOrders, 'orderNumber', [
      'desc',
    ]);
    setOriginalItems(sortedByOrderNumber);
  };

  const renderOrderNumber = useCallback(
    (
      orderNumber: string,
      order: OrderResponse,
      index: number,
      query?: string
    ) => {
      const highlighted = displayStringHighlighted(orderNumber, query);
      if (order.originOrderId && order.splitOrders) {
        return (
          <div className={'list-orderNumber'}>
            <div className={'list-table-td-monoSpaced'}>{highlighted}</div>
            <IconLinked
              fill={'var(--color-inherited)'}
              className={'list-orderNumber-isSplitOrder'}
            />
            <div
              className={
                'list-table-td-monoSpaced list-orderNumber-orderNumber'
              }
            >
              {displayStringHighlighted(
                order.splitOrders.find((s) => s.orderId === order.originOrderId)
                  ?.orderNumber,
                query
              )}
            </div>
          </div>
        );
      } else if (order.splitOrders) {
        return (
          <div className={'list-orderNumber'}>
            <div className={'list-table-td-monoSpaced'}>{highlighted}</div>
            <IconLinked
              fill={'var(--color-inherited)'}
              className={'list-orderNumber-isSplitOrder'}
            />
            <Hoverable
              onHoverNode={
                <div className={'list-orderNumber-references'}>
                  {order.splitOrders
                    .filter((x) => x.orderNumber !== order.orderNumber)
                    .map((x) => (
                      <div
                        key={x.orderNumber}
                        className={
                          'list-table-td-monoSpaced list-orderNumber-orderNumber'
                        }
                      >
                        {x.orderNumber}
                      </div>
                    ))}
                </div>
              }
            >
              <div
                className={
                  'list-table-td-monoSpaced list-orderNumber-orderNumber'
                }
              >
                {order.splitOrders.length - 1}x
              </div>
            </Hoverable>
          </div>
        );
      } else {
        return <div className={'list-table-td-monoSpaced'}>{highlighted}</div>;
      }
    },
    []
  );

  const renderWarning = useCallback((warning: boolean) => {
    if (warning) {
      return (
        <IconWarning fill="var(--color-yellow)" className="list-warning" />
      );
    } else {
      return null;
    }
  }, []);

  const renderPaymentMethod = useCallback(
    (initialPaymentMethodBrand?: string | null) => {
      return (
        <PaymentMethod initialPaymentMethodBrand={initialPaymentMethodBrand} />
      );
    },
    []
  );

  const renderCustomerName = useCallback(
    (customerName: string, order: OrderResponse) => {
      return (
        <span className={'orderList-customerName'}>
          {displayStringHighlighted(customerName, query)}
        </span>
      );
    },
    [query]
  );

  const renderAttributes = useCallback((attributes: CustomerAttributes) => {
    return (
      <span className={'orderList-customerAttributes'}>
        {attributes.isGuest ? (
          <Hoverable
            onHoverNode={
              <HintBox
                paragraphs={[t('customerAttributes.isGuest')]}
                isToolTip
              />
            }
          >
            <IconGuest
              className={'orderList-customerName-icon'}
              fill={'var(--color-primary)'}
            />
          </Hoverable>
        ) : null}
        {attributes.isLoyaltyOptIn ? (
          <Hoverable
            onHoverNode={
              <HintBox
                paragraphs={[t('customerAttributes.isLoyaltyOptIn')]}
                isToolTip
              />
            }
          >
            <IconCoin
              className={'orderList-customerName-icon'}
              fill={'var(--color-yellow)'}
            />
          </Hoverable>
        ) : null}
      </span>
    );
  }, []);

  const renderWarehouse = useCallback(
    (warehouse: WarehouseResponse | null | undefined) => {
      return <div className={'list-table-td'}>{warehouse?.name}</div>;
    },
    []
  );

  const getIgnore = () => {
    const arr = [
      'id',
      'manufacturerCompanyName',
      'originOrderId',
      'splitOrders',
      'joinedSplitOrderNumbers',
      'customer',
    ];
    if (user?.manufacturerId) {
      arr.push('manufacturer');
    }
    return arr;
  };

  const tabs: TabConfig[] = [
    {
      key: 'all',
      title: t('tabs.all'),
    },
  ];

  if (!hideUnfulfilledOrders) {
    tabs.push({
      key: 'unfulfilled',
      title: t('tabs.unfulfilled'),
      badge: unfulfilledOrders
        ? {
            title: unfulfilledOrders.length.toString() ?? '0',
            color: 'var(--color-text_secondary)',
          }
        : undefined,
    });
  }

  return (
    <div className={'orderList'}>
      <Tabs tabs={tabs} noMargin>
        <Tab>
          <div className={'orderList-tab'}>
            {currentItems ? (
              <List
                isShowingIndex
                listControls={{
                  filters: !ordersArray
                    ? [
                        dateRangeOptionFilter,
                        dateRangeFilter,
                        manufacturerFilter,
                        limitFilter,
                      ]
                    : undefined,
                  search: listControlSearch,
                }}
                items={currentItems}
                ignore={getIgnore()}
                ignoreHeaders={['warning']}
                dateStrings={['orderDateTime']}
                monoSpaceStrings={['fiegeSalesOrderNumber']}
                badgeKeys={['orderState', 'shippingState', 'transactionState']}
                linkedKey={'id'}
                linkPrefix={() => '/orders/'}
                renderObjects={[
                  {
                    key: 'customerName',
                    renderMethod: renderCustomerName,
                  },
                  {
                    key: 'customerAttributes',
                    renderMethod: renderAttributes,
                  },
                  {
                    key: 'orderNumber',
                    renderMethod: renderOrderNumber,
                  },
                  {
                    key: 'warning',
                    renderMethod: renderWarning,
                    receiveNullValues: true,
                  },
                  {
                    key: 'manufacturer',
                    renderMethod: renderManufacturer,
                  },
                  {
                    key: 'initialPaymentMethodBrand',
                    renderMethod: renderPaymentMethod,
                  },
                  {
                    key: 'warehouse',
                    renderMethod: renderWarehouse,
                  },
                ]}
                queryString={query}
                queryKeys={[
                  'customer',
                  'orderNumber',
                  'total',
                  'currentOrderState',
                  'currentShippingState',
                  'currentTransactionState',
                ]}
                sortValueFunctions={{
                  currentOrderState: (order: MinifiedOrder) => {
                    return order.orderState;
                  },
                  currentShippingState: (order: MinifiedOrder) => {
                    return order.shippingState;
                  },
                  currentTransactionState: (order: MinifiedOrder) => {
                    return order.transactionState;
                  },
                }}
                memoScrollKey={'ordersList'}
                memoScrollConditionFunc={() => {
                  return (
                    localStorage.getItem('previousPage') === 'orderDetailView'
                  );
                }}
                adjustHeightToViewport
                adjustHeightToViewportOffset={140}
                isNewTabLink
                wrapInNewTabLink={['orderNumber', 'customerName']}
                height={height ?? 400}
                tableHeadContrast
                pageItemCount={[25, 50, 100]}
              />
            ) : (
              <LoadingContainer />
            )}
          </div>
        </Tab>
        <Tab>
          {unfulfilledOrders ? (
            <UnfulfilledOrders
              unfulfilledOrders={unfulfilledOrders}
              height={height}
            />
          ) : (
            <LoadingContainer />
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default OrderList;
