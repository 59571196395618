import './areachartyaxis.css';

interface AreaChartYAxisProps {
  ticks: number[];
  bottomPadding?: number;
}

const AreaChartYAxis: React.FC<AreaChartYAxisProps> = ({
  ticks,
  bottomPadding,
}) => {
  return (
    <div
      className="areaChartYAxis"
      style={bottomPadding ? { bottom: bottomPadding } : undefined}
    >
      {[...ticks].reverse().map((tick, i) => (
        <div
          key={tick}
          className={'areaChartYAxis-tick'}
          style={{ visibility: i !== ticks.length - 1 ? 'visible' : 'hidden' }}
        >
          {tick}
        </div>
      ))}
    </div>
  );
};

export default AreaChartYAxis;
