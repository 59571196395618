import './productgroupsarray.css';
import { ProductGroupResponse } from '../../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import ValueArray from '../../../../elements/valuearray/ValueArray';
import Popup from '../../../../elements/popup/Popup';
import List from '../../../../features/list/List';
import Button from '../../../../elements/button/Button';
import useListRenderObjects from '../../../../hooks/list/useListRenderObjects';
import useListControlsSearch from '../../../../features/list/listcontrols/hooks/useListControlsSearch';
import useSortValueFunctions from '../../../../hooks/list/useSortValueFunctions';

interface productGroupsArrayProps {
  productGroups: ProductGroupResponse[] | null | undefined;
  availableProductGroups: ProductGroupResponse[];
  onDelete: (index: number) => void;
  onSubmit?: (productGroupIds: string[]) => void;
  hint?: {
    title?: string;
    paragraphs: string[];
  };
}

const ProductGroupsArray: React.FC<productGroupsArrayProps> = ({
  productGroups,
  availableProductGroups,
  onDelete,
  onSubmit,
  hint,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.productGroupsArray',
  });
  const { renderAnimalSpecies, renderArrayCount } = useListRenderObjects();
  const { listControlSearch, currentItems } = useListControlsSearch(
    t('popup.search'),
    availableProductGroups,
    ['identifier', 'name.de-DE', 'name.en-GB']
  );
  const { translatedValue, simpleValue } = useSortValueFunctions();

  const [popup, setPopup] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState<
    ProductGroupResponse[] | null | undefined
  >(productGroups);

  useEffect(() => {
    setSelectedGroups(productGroups);
  }, [productGroups]);

  return (
    <div className={'productGroupsArray'}>
      <ValueArray
        title={t('productGroups')}
        hint={hint}
        items={productGroups}
        titleKey={'name'}
        titleKeyLang={'de-DE'}
        onEdit={onSubmit ? () => setPopup(true) : undefined}
        onDelete={onSubmit ? onDelete : undefined}
        emptyStateText={t('emptyArray')}
      />
      <Popup toggled={popup} width={'70%'} close={() => setPopup(false)}>
        <div className={'popup-title'}>{t('popup.title')}</div>
        <List
          items={currentItems}
          selectedItems={selectedGroups}
          onSelect={(group) => {
            if (selectedGroups) {
              const update = [...selectedGroups];
              const i = update?.findIndex((g) => g.id === group.id);
              if (i !== -1) {
                update?.splice(i, 1);
              } else {
                update.push(group);
              }
              setSelectedGroups(update);
            }
          }}
          onSelectAll={() => {
            if (
              selectedGroups &&
              selectedGroups.length === currentItems?.length
            ) {
              setSelectedGroups([]);
            } else {
              setSelectedGroups(currentItems);
            }
          }}
          ignore={['id', 'updatedAt', 'additionalPropertyValidationRules']}
          monoSpaceStrings={['createdAt', 'identifier']}
          translatedStrings={['name', 'description']}
          dateStrings={['createdAt']}
          renderObjects={[
            {
              key: 'animalSpecies',
              renderMethod: renderAnimalSpecies,
            },
            {
              key: 'propertyGroups',
              renderMethod: renderArrayCount,
            },
          ]}
          sortValueFunctions={{
            name: (x) => translatedValue(x, 'name'),
            description: (x) => translatedValue(x, 'description'),
            identifier: (x) => simpleValue(x, 'identifier'),
          }}
          listControls={{
            search: listControlSearch,
          }}
          height={'50vh'}
        />
        <div className={'global-cardActions-postBorder'}>
          <Button
            cta={t('popup.cta')}
            look={'secondary'}
            width={'minimal'}
            action={() => {
              if (selectedGroups && onSubmit) {
                onSubmit(selectedGroups.map((g) => g.id));
                setPopup(false);
              }
            }}
          />
        </div>
      </Popup>
    </div>
  );
};

export default ProductGroupsArray;
