import './salesoverviewsubelement.css';
import ValueComparison from '../../../../elements/valuecomparison/ValueComparison';
import { SalesOverViewMode } from '../SalesOverview';
import useNumberFormat from '../../../../hooks/useNumberFormat';

interface SalesOverViewSubElementProps {
  title: string;
  value: number | null;
  compareValue: number | null;
  mode: SalesOverViewMode;
}

const SalesOverViewSubElement: React.FC<SalesOverViewSubElementProps> = ({
  title,
  value,
  compareValue,
  mode,
}) => {
  const { renderCurrency } = useNumberFormat();

  return (
    <div className="salesOverviewSubElement">
      <div className="salesOverviewSubElement-title">{title}</div>
      <div className="salesOverviewSubElement-value">
        <div className="salesOverviewSubElement-value-amount">
          {mode === 'revenue' ? renderCurrency(value) : value}
        </div>
        {value !== null && compareValue !== null ? (
          <div className="salesOverviewSubElement-value-comparison">
            <ValueComparison value={value} compareValue={compareValue} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SalesOverViewSubElement;
