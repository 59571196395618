import './dropdownmenu.css';
import { DropdownOption } from '../selectors/Selectors';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { SmallHint } from '../hint/Hint';
import { ReactComponent as IconLinked } from '../../../assets/icon/linked.svg';
import { ReactComponent as IconUnlinked } from '../../../assets/icon/unlinked.svg';
import { ReactComponent as IconLock } from '../../../assets/icon/padlock.svg';
import { ReactComponent as IconArrow } from '../../../assets/icon/arrow_dropdown.svg';
import { useEffect, useRef, useState } from 'react';
import ClickOutsideContainer from '../clickoutsidecontianer/ClickOutsideContainer';
import Searchbar from '../searchbar/Searchbar';
import { LoadingContainer } from '../loading/Loading';
import Button from '../button/Button';

interface DropdownMenuProps {
  options?: string[];
  optionObjects?: DropdownOption[] | null;
  helperCSSClass?: string;
  onSelect?: (value: string, id?: string) => void;
  onClearAll?: () => void;
  title?: string;
  hint?: {
    title?: string;
    paragraphs?: string[];
  };
  selected?: string | null;
  multiSelected?: string[] | null;
  inherited?: string | null;
  defaultOptionText?: string;
  required?: boolean;
  disabled?: boolean;
  errors?: string[] | null;
  inputEmbedded?: boolean;
  locked?: boolean;
  height?: number;
  widthAuto?: boolean;
  disableAutoOptionSorting?: boolean;
  disableSearch?: boolean;
  highlightedOptions?: string[];
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  options,
  optionObjects,
  helperCSSClass,
  onSelect,
  onClearAll,
  title,
  hint,
  selected,
  multiSelected,
  inherited,
  defaultOptionText,
  required,
  disabled,
  errors,
  inputEmbedded,
  locked,
  height,
  widthAuto,
  disableAutoOptionSorting,
  disableSearch,
  highlightedOptions,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.dropdownMenu',
  });
  const [menu, setMenu] = useState(false);
  const [results, setResults] = useState<string[] | DropdownOption[] | null>(
    null
  );
  const [query, setQuery] = useState('');
  const menuRef = useRef<HTMLDivElement>(null);

  let c = `dropdownMenu
  ${helperCSSClass ? ' ' + helperCSSClass : ''}
  ${(required && !selected) || errors ? ' dropdownMenu-alert' : ''}
  ${disabled ? ' dropdownMenu-disabled' : ''}
  ${inherited && !selected ? ' dropdownMenu-inherited' : ''}
  ${inputEmbedded ? ' dropdownMenu-inputEmbedded' : ''}`;

  useEffect(() => {
    filterOptions();
  }, [query, optionObjects, options]);

  const filterOptions = () => {
    if (query !== '') {
      let filteredOptions: string[] | DropdownOption[] | null = null;
      if (options && !disableAutoOptionSorting) {
        filteredOptions = options.filter((option) =>
          option.toLowerCase().includes(query.toLowerCase())
        );
      } else if (optionObjects && !disableAutoOptionSorting) {
        filteredOptions = optionObjects.filter((option) =>
          option.name.toLowerCase().includes(query.toLowerCase())
        );
      }
      setResults(filteredOptions);
    } else {
      if (options && !disableAutoOptionSorting) {
        const sorted = [...options].sort();
        setResults(sorted);
      } else if (optionObjects && !disableAutoOptionSorting) {
        optionObjects = _.sortBy(optionObjects, ['name']);
        setResults(optionObjects);
      }
    }
  };

  return (
    <div className={'dropdownMenu-wrapper'}>
      {title ? (
        <div className="dropdownMenu-title-wrapper">
          {title && inherited !== null && inherited !== undefined ? (
            locked ? (
              <IconLock
                fill="var(--color-inherited)"
                className="global-icon-inherited"
              />
            ) : !selected ? (
              <IconLinked
                className="global-icon-inherited"
                fill="var(--color-inherited)"
              />
            ) : (
              <IconUnlinked
                className="global-icon-inherited"
                fill="var(--color-text_tertiary)"
              />
            )
          ) : null}
          <div
            className={
              inherited && !selected
                ? 'dropdown-title-inherited'
                : 'dropdown-title'
            }
          >
            {title}
          </div>
          {required ? <div className="global-required">•</div> : null}
          {hint ? (
            <SmallHint title={hint.title} paragraphs={hint.paragraphs} />
          ) : null}
        </div>
      ) : null}
      <div
        className={c}
        ref={menuRef}
        onClick={() => {
          if (!disabled) setMenu(true);
        }}
        style={{ display: widthAuto ? 'inline-flex' : 'flex', height: height }}
      >
        <div className={'dropdownMenu-value'}>
          {multiSelected
            ? multiSelected.length >= 1
              ? multiSelected.length + ' ' + t('multiSelected')
              : defaultOptionText
            : selected ?? defaultOptionText ?? t('select')}
        </div>
        <IconArrow
          className={'dropdownMenu-icon'}
          fill={'var(--color-text_primary)'}
        />
      </div>
      {menu ? (
        <ClickOutsideContainer
          onClickOutside={() => setMenu(false)}
          fixedViewportAwareElementRef={menuRef}
          offsetY={42}
        >
          <div className={'dropdownMenu-menu'}>
            {!disableSearch ? (
              <div className={'dropdownMenu-menu-search'}>
                <Searchbar
                  cta={t('search')}
                  height={'normal'}
                  onDebouncedSearch={(q) => setQuery(q)}
                />
              </div>
            ) : null}
            {onClearAll ? (
              <div className={'dropdownMenu-menu-search'}>
                <Button
                  cta={t('clearAll')}
                  width={'full'}
                  look={'secondary'}
                  action={onClearAll}
                />
              </div>
            ) : null}
            <div className={'dropdownMenu-menu-items'}>
              {results ? (
                results.map((result) => {
                  if (result) {
                    const value =
                      typeof result !== 'string' ? result.name : result;
                    const id =
                      typeof result !== 'string' ? result.id : undefined;
                    return (
                      <div
                        className={`dropdownMenu-menu-item ${
                          highlightedOptions?.includes(value)
                            ? 'dropdownMenu-menu-item__highlighted'
                            : ''
                        } ${
                          selected === value || multiSelected?.includes(value)
                            ? 'dropdownMenu-menu-item__selected'
                            : ''
                        }`}
                        onClick={
                          onSelect
                            ? () => {
                                onSelect(value, id);
                                setQuery('');
                                if (!multiSelected) {
                                  setMenu(false);
                                }
                              }
                            : undefined
                        }
                      >
                        {value}
                      </div>
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                <LoadingContainer />
              )}
            </div>
          </div>
        </ClickOutsideContainer>
      ) : null}
    </div>
  );
};

export default DropdownMenu;
