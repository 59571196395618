import { usePetCloudApi } from '../api/PetCloudApi';
import { useErrorHandler } from '../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import { DropdownOption } from '../elements/selectors/Selectors';
import { AnimalSpeciesResponse } from '../api/petcloudapi/api';
import TranslatedStringIndex from '../types/TranslatedStringIndex';
import { useTranslation } from 'react-i18next';

export type AnimalSpeciesDict = { [key: string]: AnimalSpeciesResponse };

type AnimalSpeciesOptionsOptions = {
  noAllSpecies?: boolean;
  presetAnimalSpeciesOptions?: DropdownOption[] | null;
};

const useAnimalSpeciesOptions = (opts?: AnimalSpeciesOptionsOptions) => {
  const { i18n } = useTranslation();
  const api = usePetCloudApi();
  const animalSpeciesApi = api.animalSpeciesApi();
  const errorHandler = useErrorHandler();

  const [animalSpeciesOptions, setAnimalSpeciesOptions] = useState<
    DropdownOption[] | null
  >(opts?.presetAnimalSpeciesOptions ?? null);

  const [animalSpeciesDict, setAnimalSpeciesDict] =
    useState<AnimalSpeciesDict | null>(null);

  useEffect(() => {
    animalSpeciesApi
      .animalSpeciesGetAnimalSpecies()
      .then((response) => {
        console.log(response);
        const options = response.data.map((species) => {
          return {
            id: species.id,
            name:
              species.name[i18n.language as TranslatedStringIndex] ??
              'brand translation missing',
          };
        });

        // fill dictionary
        const dict: AnimalSpeciesDict = {};
        response.data.forEach((species) => {
          dict[species.id] = species;
        });
        setAnimalSpeciesDict(dict);

        if (!opts?.presetAnimalSpeciesOptions) {
          setAnimalSpeciesOptions(options);
        }
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  }, []);

  return {
    animalSpeciesOptions: animalSpeciesOptions,
    animalSpeciesDict: animalSpeciesDict,
  };
};

export default useAnimalSpeciesOptions;
