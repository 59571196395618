import './validationsdeletion.css';
import Popup from '../../../elements/popup/Popup';
import DropdownMenu from '../../../elements/dropdownmenu/DropdownMenu';
import Button from '../../../elements/button/Button';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import { DropdownOption } from '../../../elements/selectors/Selectors';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import useNotifications from '../../../hooks/useNotifications';

interface ValidationsDeletionProps {
  manufacturerOptions?: DropdownOption[] | null;
  isPopupOpen: boolean;
  setPopupOpen: (bool: boolean) => void;
  onDeleteSuccess: () => void;
}

const ValidationsDeletion: React.FC<ValidationsDeletionProps> = ({
  manufacturerOptions,
  isPopupOpen,
  setPopupOpen,
  onDeleteSuccess,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.productValidation.deletePopup',
  });
  const api = usePetCloudApi();
  const productsApi = api.productsApi();
  const errorHandler = useErrorHandler();
  const { pushNotification } = useNotifications();

  const [manufacturerId, setManufacturerId] = useState<string | undefined>(
    undefined
  );
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteList = () => {
    setIsDeleting(true);
    productsApi
      .productsDeleteValidationResults(manufacturerId)
      .then((response) => {
        console.log(response);
        setIsDeleting(false);
        pushNotification(t('notifications.delete_success'));
        onDeleteSuccess();
        setPopupOpen(false);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsDeleting(false);
      });
  };

  return (
    <Popup
      toggled={isPopupOpen}
      width={'30%'}
      close={() => setPopupOpen(false)}
    >
      <div className={'popup-title'}>{t('title')}</div>
      <div className={'global-textElement'}>{t('text')}</div>
      {manufacturerOptions ? (
        <DropdownMenu
          title={t('manufacturer')}
          optionObjects={manufacturerOptions.filter((x) => x.id !== 'all')}
          selected={
            manufacturerOptions.find((x) => x.id === manufacturerId)?.name
          }
          onSelect={(name, id) => {
            if (id) setManufacturerId(id);
          }}
          helperCSSClass={'validationsDeletion-dropdown'}
        />
      ) : null}
      <Button
        cta={t('cta')}
        look={'danger'}
        width={'full'}
        action={deleteList}
        isLoading={isDeleting}
      />
    </Popup>
  );
};

export default ValidationsDeletion;
