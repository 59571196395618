import './warehouses.css';
import { useEffect, useState } from 'react';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import {
  WarehouseResponse,
  CreateWarehouseRequest,
  WarehouseType,
} from '../../../api/petcloudapi/api';
import { LoadingContainer } from '../../../elements/loading/Loading';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import Button from '../../../elements/button/Button';
import { useTranslation } from 'react-i18next';
import Popup from '../../../elements/popup/Popup';
import List from '../../../features/list/List';
import Input from '../../../elements/input/Input';
import {
  Dropdown,
  DropdownOption,
} from '../../../elements/selectors/Selectors';
import useNotifications from '../../../hooks/useNotifications';
import useListControlsSearch from '../../../features/list/listcontrols/hooks/useListControlsSearch';

const Warehouses = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.admin.warehouses',
  });
  const { pushNotification } = useNotifications();
  const api = usePetCloudApi();
  const errorHandler = useErrorHandler();
  const warehousesApi = api.warehousesApi();
  const manufacturersApi = api.manufacturersApi();
  const { listControlSearch, currentItems, query, setOriginalItems } =
    useListControlsSearch(t('search'), null, ['name']);
  const [selectedWarehouses, setSelectedWarehouses] = useState<
    WarehouseResponse[]
  >([]);
  const [newWarehouse, setNewWarehouse] = useState<CreateWarehouseRequest>({
    name: '',
    type: 'CentralWarehouse' as WarehouseType,
    manufacturerId: null,
  });
  const [manufacturers, setManufacturers] = useState<DropdownOption[] | null>(
    null
  );
  const [dangerPopup, setDangerPopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newPopup, setNewPopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [warehouseToBeEdited, setWarehouseToBeEdited] =
    useState<WarehouseResponse | null>(null);

  useEffect(() => {
    getWarehouses();
    getManufacturers();
  }, []);

  const getManufacturers = () => {
    manufacturersApi
      .manufacturersGetSimpleManufacturers()
      .then((response) => {
        console.log(response);
        const result = response.data.map((manufacturer) => {
          return {
            id: manufacturer.id,
            name: manufacturer.companyName,
          };
        });
        setManufacturers(result);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getWarehouses = () => {
    warehousesApi
      .warehousesGetWarehouses()
      .then((response) => {
        console.log(response.data);
        setOriginalItems(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const selectWarehouse = (item: WarehouseResponse) => {
    const update = selectedWarehouses;
    const i = update.findIndex((warehouse) => warehouse.id === item.id);
    if (i !== -1) {
      update.splice(i, 1);
    } else {
      update.push(item);
    }
    setSelectedWarehouses([...update]);
  };

  const selectAllWarehouses = () => {
    if (currentItems && currentItems.length !== selectedWarehouses.length) {
      setSelectedWarehouses([...currentItems]);
    } else {
      setSelectedWarehouses([]);
    }
  };

  const deleteSelectedWarehouses = () => {
    setIsSubmitting(true);
    const promises = selectedWarehouses.map((warehouse) => {
      return deleteWarehouse(warehouse.id);
    });

    Promise.all(promises)
      .then(() => {
        pushNotification(t('notifications.delete_successful'));
        setDangerPopup(false);
        setIsSubmitting(false);
        setSelectedWarehouses([]);
        getWarehouses();
      })
      .catch(() => {
        pushNotification(t('notifications.delete_failed'));
        setDangerPopup(false);
        setIsSubmitting(false);
        setSelectedWarehouses([]);
        getWarehouses();
      });
  };

  const deleteWarehouse = (id: string) => {
    return new Promise((resolve, reject) => {
      warehousesApi
        .warehousesDeleteWarehouse(id)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          reject(error);
        });
    });
  };

  const submitNewWarehouse = () => {
    setIsSubmitting(true);
    warehousesApi
      .warehousesCreateWarehouse(newWarehouse)
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.create_successful'));
        setIsSubmitting(false);
        setNewWarehouse({
          name: '',
          type: 'CentralWarehouse' as WarehouseType,
          manufacturerId: null,
        });
        setNewPopup(false);
        getWarehouses();
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  const submitEditedTag = () => {
    if (warehouseToBeEdited) {
      setIsSubmitting(true);
      warehousesApi
        .warehousesUpdateWarehouse(warehouseToBeEdited.id, warehouseToBeEdited)
        .then((response) => {
          console.log(response);
          pushNotification(t('notifications.edit_successful'));
          setIsSubmitting(false);
          getWarehouses();
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsSubmitting(false);
        });
    }
  };

  if (currentItems && manufacturers) {
    return (
      <div className="warehouses">
        <List
          items={currentItems}
          selectedItems={selectedWarehouses}
          onSelect={selectWarehouse}
          onSelectAll={selectAllWarehouses}
          ignore={['updatedAt', 'id', 'encryptedProperties']}
          dateStrings={['createdAt', 'syncedAt']}
          monoSpaceStrings={['manufacturerId', 'shopReferenceId']}
          onRowClick={(item: WarehouseResponse) => {
            setWarehouseToBeEdited({ ...item });
            setEditPopup(true);
          }}
          actions={[
            {
              cta: 'edit',
              look: 'blue',
              action: (item: WarehouseResponse) => {
                setWarehouseToBeEdited({ ...item });
                setEditPopup(true);
              },
            },
            {
              cta: 'delete',
              look: 'danger',
              action: (item: WarehouseResponse) => {
                setSelectedWarehouses([item]);
                setDangerPopup(true);
              },
            },
          ]}
          queryString={query}
          queryKeys={['name']}
          listControls={{
            search: listControlSearch,
            rightAlignedChildren: [
              <Button
                width="minimal"
                look={'primary'}
                cta={t('new')}
                action={() => setNewPopup(true)}
              />,
            ],
          }}
          actionsBar={
            selectedWarehouses.length > 0
              ? {
                  buttons: [
                    <Button
                      cta={t('actions.delete')}
                      width={'tiny'}
                      look="secondary-danger"
                      action={() => setDangerPopup(true)}
                      margin="right"
                    />,
                  ],
                }
              : undefined
          }
          adjustHeightToViewport
          adjustHeightToViewportOffset={120}
          tableHeadContrast
        />
        <Popup
          width="30%"
          toggled={dangerPopup}
          close={() => setDangerPopup(false)}
        >
          <div className="popup-title">{t('dangerPopup.title')}</div>
          <div className="tags-dangerPopup-message">
            {t('dangerPopup.message')}
          </div>
          <Button
            width="full"
            look="danger"
            cta={t('dangerPopup.cta')}
            action={deleteSelectedWarehouses}
            isLoading={isSubmitting}
          />
        </Popup>
        <Popup width="40%" toggled={newPopup} close={() => setNewPopup(false)}>
          <div className="popup-title">{t('newPopup.title')}</div>
          <WarehouseForm
            warehouse={newWarehouse}
            setWarehouse={setNewWarehouse}
            manufacturers={manufacturers}
          />
          <div className="global-cardActions">
            <Button
              width="minimal"
              look="save"
              cta={t('newPopup.submit')}
              action={submitNewWarehouse}
              isLoading={isSubmitting}
            />
          </div>
        </Popup>
        <Popup
          key={warehouseToBeEdited?.id}
          width="40%"
          toggled={editPopup}
          close={() => setEditPopup(false)}
        >
          <div className="popup-title">{t('editPopup.title')}</div>
          {warehouseToBeEdited ? (
            <WarehouseForm
              warehouse={warehouseToBeEdited}
              setWarehouse={(
                warehouse: WarehouseResponse | CreateWarehouseRequest
              ) => setWarehouseToBeEdited(warehouse as WarehouseResponse)}
              manufacturers={manufacturers}
            />
          ) : null}
          <div className="global-cardActions">
            <Button
              width="minimal"
              look="save"
              cta={t('actions.save')}
              action={submitEditedTag}
              isLoading={isSubmitting}
            />
          </div>
        </Popup>
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default Warehouses;

interface WarehouseFormProps {
  warehouse: WarehouseResponse | CreateWarehouseRequest;
  setWarehouse: (warehouse: WarehouseResponse | CreateWarehouseRequest) => void;
  manufacturers: DropdownOption[];
}

const WarehouseForm: React.FC<WarehouseFormProps> = ({
  warehouse,
  setWarehouse,
  manufacturers,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.admin.warehouses',
  });
  return (
    <div className="warehouses-groupForm">
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <Input
            title={t('newPopup.name')}
            content={warehouse.name}
            update={(e) => {
              setWarehouse({
                ...warehouse,
                name: e,
              });
            }}
            required={true}
          />
        </div>
      </div>
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <Dropdown
            title={t('newPopup.type')}
            selected={warehouse.type}
            options={['CentralWarehouse', 'ManufacturerWarehouse']}
            update={(e) => {
              setWarehouse({
                ...warehouse,
                type: e.target.selectedOptions[0].value as WarehouseType,
              });
            }}
            required={true}
          />
        </div>
        <div className="global-inputGroup-input">
          <Dropdown
            title={t('newPopup.manufacturer')}
            selected={
              manufacturers.find((m) => m.id === warehouse.manufacturerId)?.name
            }
            optionObjects={manufacturers}
            update={(e) => {
              setWarehouse({
                ...warehouse,
                manufacturerId:
                  e.target.selectedOptions[0].getAttribute('data-value'),
              });
            }}
            required={true}
          />
        </div>
      </div>
      <div className={'input-title'}>{t('newPopup.dhlReturnsApi.title')}</div>
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <Input
            title={t('newPopup.dhlReturnsApi.username')}
            content={warehouse.encryptedProperties?.['DhlReturnsApiUsername']}
            update={(e) => {
              setWarehouse({
                ...warehouse,
                encryptedProperties: {
                  ...warehouse.encryptedProperties,
                  DhlReturnsApiUsername: e,
                },
              });
            }}
          />
        </div>
        <div className="global-inputGroup-input">
          <Input
            title={t('newPopup.dhlReturnsApi.password')}
            content={warehouse.encryptedProperties?.['DhlReturnsApiPassword']}
            update={(e) => {
              setWarehouse({
                ...warehouse,
                encryptedProperties: {
                  ...warehouse.encryptedProperties,
                  DhlReturnsApiPassword: e,
                },
              });
            }}
          />
        </div>
      </div>
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <Input
            title={t('newPopup.dhlReturnsApi.key')}
            content={warehouse.encryptedProperties?.['DhlReturnsApiKey']}
            update={(e) => {
              setWarehouse({
                ...warehouse,
                encryptedProperties: {
                  ...warehouse.encryptedProperties,
                  DhlReturnsApiKey: e,
                },
              });
            }}
          />
        </div>
      </div>
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <Input
            title={t('newPopup.dhlReturnsApi.basePath')}
            content={warehouse.encryptedProperties?.['DhlReturnsApiBasePath']}
            update={(e) => {
              setWarehouse({
                ...warehouse,
                encryptedProperties: {
                  ...warehouse.encryptedProperties,
                  DhlReturnsApiBasePath: e,
                },
              });
            }}
          />
        </div>
      </div>
      <div className="global-inputGroup">
        <div className="global-inputGroup-input">
          <Input
            title={t('newPopup.dhlReturnsApi.receiverId')}
            content={warehouse.encryptedProperties?.['DhlReturnsApiReceiverId']}
            update={(e) => {
              setWarehouse({
                ...warehouse,
                encryptedProperties: {
                  ...warehouse.encryptedProperties,
                  DhlReturnsApiReceiverId: e,
                },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
