import './propertiescell.css';
import { PropertyOptionResponse } from '../../../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import TranslatedStringIndex from '../../../../../types/TranslatedStringIndex';

interface PropertiesCellProps {
  options: PropertyOptionResponse[];
  selectCellElement: (
    element: PropertyOptionResponse,
    compatibleHeaders?: string[]
  ) => void;
  isSelectedCellElement: (element: any) => boolean;
}

const compatibleHeaders = ['variantOptions'];

const PropertiesCell: React.FC<PropertiesCellProps> = ({
  options,
  selectCellElement,
  isSelectedCellElement,
}) => {
  const { i18n } = useTranslation();

  return (
    <div className={'jtce-properties'}>
      {options.map((option, index) => {
        return (
          <div
            key={index}
            className={`jtce-bubble ${
              isSelectedCellElement(option) ? 'jtce-bubble__selected' : ''
            }`}
            onClick={() => selectCellElement(option, compatibleHeaders)}
          >
            {option.name[i18n.language as TranslatedStringIndex] ??
              'missing translation'}
          </div>
        );
      })}
    </div>
  );
};

export default PropertiesCell;
