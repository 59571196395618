import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreateOrderShippingDetailRequest,
  OrderPositionResponse,
  OrderResponse,
  UpdateOrderShippingDetailRequest,
} from '../../../../api/petcloudapi/api';
import Button from '../../../../elements/button/Button';
import DatePicker from '../../../../elements/datepicker/DatePicker';
import Input from '../../../../elements/input/Input';
import PositionEntry from '../../../../types/PositionEntry';
import TranslatedStringIndex from '../../../../types/TranslatedStringIndex';
import {
  getNewShippingDetailPositionEntries,
  getShippingDetailPositionEntries,
} from '../../positions/Positions';
import './shippingdetailedit.css';
import List from '../../../../features/list/List';
import { EmptyState } from '../../../../elements/emptystate/EmptyState';
import Popup from '../../../../elements/popup/Popup';
import PositionTransfer from '../positiontransfer/PositionTransfer';
import useDateTools from '../../../../hooks/useDateTools';
import { ReactComponent as IconTrash } from '../../../../../assets/icon/trash.svg';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import {
  Check,
  Dropdown,
  DropdownOption,
} from '../../../../elements/selectors/Selectors';
import { LoadingContainer } from '../../../../elements/loading/Loading';

interface ShippingDetailEditProps {
  order: OrderResponse;
  orderPositions: OrderPositionResponse[];
  shippingDetail:
    | CreateOrderShippingDetailRequest
    | UpdateOrderShippingDetailRequest;
  submit: (
    request: CreateOrderShippingDetailRequest | UpdateOrderShippingDetailRequest
  ) => void;
  isSubmitting: boolean;
}

const ShippingDetailEdit: React.FC<ShippingDetailEditProps> = ({
  order,
  orderPositions,
  shippingDetail,
  submit,
  isSubmitting,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.order.shippingDetails.edit',
  });
  const api = usePetCloudApi();
  const shippingProvidersApi = api.shippingProvidersApi();
  const errorHandler = useErrorHandler();
  const { getDateOnlyFromDate } = useDateTools();
  const [request, setRequest] = useState<
    CreateOrderShippingDetailRequest | UpdateOrderShippingDetailRequest
  >(shippingDetail);
  const [positions, setPositions] = useState<PositionEntry[]>([]);
  const [orderShippingDetailPositions, setOrderShippingDetailPositions] =
    useState<PositionEntry[]>([]);
  const [positionTransferPopup, setPositionTransferPopup] = useState(false);
  const [shippingProviders, setShippingProviders] = useState<
    DropdownOption[] | null
  >(null);

  useEffect(() => {
    getShippingProviders();
    getPositions();
  }, []);

  const getShippingProviders = () => {
    shippingProvidersApi
      .shippingProvidersGetShippingProviders()
      .then((response) => {
        console.log(response);
        setShippingProviders(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getPositions = () => {
    setPositions(
      getNewShippingDetailPositionEntries(
        orderPositions,
        i18n.language as TranslatedStringIndex,
        order.currency?.symbol
      )
    );

    if (shippingDetail.positions && shippingDetail.positions.length > 0) {
      setOrderShippingDetailPositions(
        getShippingDetailPositionEntries(
          i18n.language as TranslatedStringIndex,
          orderPositions,
          shippingDetail.positions,
          order.currency?.symbol
        )
      );
    }
  };

  const renderNewShippingDetailQuantity = (
    quantity: string,
    item: PositionEntry,
    index: number
  ) => {
    return (
      <div className="shippingDetailEdit-quantityInput">
        <Input
          content={quantity}
          update={(e) => {
            const update = [...orderShippingDetailPositions];
            update[index] = {
              ...update[index],
              quantity: parseInt(e),
            };
            setOrderShippingDetailPositions(update);
          }}
        />
      </div>
    );
  };

  const renderNewShippingDetailBatchNumber = (
    batchNumber: string,
    item: PositionEntry,
    index: number
  ) => {
    return (
      <Input
        content={batchNumber}
        update={(e) => {
          const update = [...orderShippingDetailPositions];
          update[index] = {
            ...update[index],
            batchNumber: e,
          };
          setOrderShippingDetailPositions(update);
        }}
      />
    );
  };

  const renderNewShippingDetailBestBefore = (
    bestBefore: string,
    item: PositionEntry,
    index: number
  ) => {
    return (
      <DatePicker
        selected={new Date(bestBefore)}
        onChange={(date) => {
          const update = [...orderShippingDetailPositions];
          update[index] = {
            ...update[index],
            bestBefore: getDateOnlyFromDate(date),
          };
          setOrderShippingDetailPositions(update);
        }}
        dateFormat={'yyyy-MM-dd'}
      />
    );
  };

  if (shippingProviders) {
    if (positions.length > 0) {
      return (
        <div className="shippingDetailEdit">
          <div className="global-inputGroup">
            <div className={'global-inputGroup-input'}>
              <Dropdown
                title={t('shippingProviderId')}
                optionObjects={shippingProviders}
                selected={
                  shippingProviders.find(
                    (s) => s.id === request.shippingProviderId
                  )?.name
                }
                update={(e) => {
                  const id =
                    e.currentTarget.selectedOptions[0].getAttribute(
                      'data-value'
                    );
                  const shippingProviderId = shippingProviders?.find(
                    (s) => s.id === id
                  )?.id;
                  if (shippingProviderId) {
                    setRequest({
                      ...request,
                      shippingProviderId: shippingProviderId,
                    });
                  }
                }}
                required
              />
            </div>
            <div className="global-inputGroup-input">
              <Input
                title={t('trackingCode')}
                content={request.trackingCode}
                update={(e) => {
                  setRequest({
                    ...request,
                    trackingCode: e,
                  });
                }}
                hint={t('trackingCode_hint')}
                hintTitle={t('trackingCode_title')}
              />
            </div>
            <div className="global-inputGroup-input">
              <Input
                title={t('weight')}
                content={request.weight?.toString()}
                update={(e) => {
                  setRequest({
                    ...request,
                    weight: parseFloat(e),
                  });
                }}
                type={'number'}
                unit={'kg'}
              />
            </div>
          </div>
          <div className="global-inputGroup">
            <div className="global-inputGroup-input">
              <DatePicker
                title={t('transmissionDate')}
                selected={
                  request.transmissionDate
                    ? new Date(request.transmissionDate)
                    : null
                }
                onChange={(date) => {
                  setRequest({
                    ...request,
                    transmissionDate: date?.toISOString(),
                  });
                }}
              />
            </div>
            <div className="global-inputGroup-input">
              <DatePicker
                title={t('shippingDateEarliest')}
                selected={
                  request.shippingDateEarliest
                    ? new Date(request.shippingDateEarliest)
                    : null
                }
                onChange={(date) => {
                  setRequest({
                    ...request,
                    shippingDateEarliest: date?.toISOString(),
                  });
                }}
              />
            </div>
            <div className="global-inputGroup-input">
              <DatePicker
                title={t('shippingDateLatest')}
                selected={
                  request.shippingDateLatest
                    ? new Date(request.shippingDateLatest)
                    : null
                }
                onChange={(date) => {
                  setRequest({
                    ...request,
                    shippingDateLatest: date?.toISOString(),
                  });
                }}
              />
            </div>
          </div>
          {'notifyCustomer' in request ? (
            <div className="global-inputGroup">
              <div className="global-inputGroup-input">
                <Check
                  checked={!!request.notifyCustomer}
                  text={t('notifyCustomer')}
                  update={() => {
                    setRequest({
                      ...request,
                      notifyCustomer: !request.notifyCustomer,
                    });
                  }}
                />
              </div>
            </div>
          ) : null}
          <div className={'shippingDetailEdit-positions'}>
            {orderShippingDetailPositions &&
            orderShippingDetailPositions.length > 0 ? (
              <List
                key={'newShippingDetailPositions'}
                items={orderShippingDetailPositions}
                renderObjects={[
                  {
                    key: 'quantity',
                    renderMethod: renderNewShippingDetailQuantity,
                    stopPropagation: true,
                  },
                  {
                    key: 'batchNumber',
                    renderMethod: renderNewShippingDetailBatchNumber,
                    stopPropagation: true,
                  },
                  {
                    key: 'bestBefore',
                    renderMethod: renderNewShippingDetailBestBefore,
                    stopPropagation: true,
                  },
                ]}
                itemImgKey="coverUri"
                monoSpaceStrings={['productNumber', 'ean']}
                ignore={['id', 'productId', 'positionId', 'currency']}
                priceKeysConfig={{
                  priceKeys: ['price'],
                  currencyKey: 'currency',
                }}
                singleAction={{
                  actionIcon: (
                    <IconTrash
                      className={'button-icon'}
                      fill={'var(--color-danger)'}
                    />
                  ),
                  action: (item, index) => {
                    const update = [...orderShippingDetailPositions];
                    update.splice(index, 1);
                    setOrderShippingDetailPositions(update);
                  },
                }}
              />
            ) : (
              <EmptyState message={t('positions_empty')} />
            )}
            <Popup
              toggled={positionTransferPopup}
              width={'50%'}
              close={() => setPositionTransferPopup(false)}
            >
              <div className={'popup-title'}>{t('positionTransfer.title')}</div>
              <PositionTransfer
                currentShippingDetails={order.orderShippingDetails}
                orderPositions={positions}
                onTransferPositions={(x) => {
                  if (orderShippingDetailPositions) {
                    const update = [...orderShippingDetailPositions];
                    setOrderShippingDetailPositions(update.concat(x));
                    setPositionTransferPopup(false);
                  } else {
                    setOrderShippingDetailPositions(x);
                    setPositionTransferPopup(false);
                  }
                }}
              />
            </Popup>
          </div>
          <div className="global-cardActions-postBorder global-cardActions-spaceBetween">
            <Button
              cta={t('positionTransfer.title')}
              look={'secondary'}
              width={'minimal'}
              action={() => setPositionTransferPopup(true)}
            />
            <Button
              cta={t('submit')}
              look="save"
              width="minimal"
              action={() =>
                submit({
                  ...request,
                  positions: orderShippingDetailPositions.map((psoition) => {
                    return {
                      quantity: psoition.quantity,
                      batchNumber: psoition.batchNumber,
                      bestBefore: psoition.bestBefore,
                      orderPositionId: psoition.positionId,
                      orderId: order.id,
                    };
                  }),
                })
              }
              isLoading={isSubmitting}
              active={
                !!orderShippingDetailPositions &&
                orderShippingDetailPositions.length > 0
              }
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  } else {
    return <LoadingContainer />;
  }
};

export default ShippingDetailEdit;
