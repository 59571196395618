import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { BrandStoreResponse } from '../../api/petcloudapi/api';
import { useUser } from '../../contexts/auth/User';
import Button from '../../elements/button/Button';
import Card, { CardSection } from '../../elements/card/Card';
import { EmptyState } from '../../elements/emptystate/EmptyState';
import List from '../../features/list/List';
import { LoadingContainer } from '../../elements/loading/Loading';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { Navigator, Stage } from '../../layout/stage/Stage';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import { Has, useAuthorization } from '../../contexts/auth/Authorization';
import useListControlsSearch from '../../features/list/listcontrols/hooks/useListControlsSearch';

type Brand = BrandStoreResponse & { brandName: string };

const Shops = () => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.shops',
  });
  const { user } = useUser();
  const link = useNavigate();
  const api = usePetCloudApi();
  const brandStoresApi = api.brandStoresApi();
  const errorHandler = useErrorHandler();
  const { authorizations } = useAuthorization();
  const { listControlSearch, query, currentItems, setOriginalItems } =
    useListControlsSearch<Brand>(t('search_cta'), null, ['name', 'brandName']);

  useEffect(() => {
    if (authorizations?.includes('brand_stores:list')) {
      getBrandStores();
    }
  }, [authorizations]);

  const getBrandStores = () => {
    brandStoresApi
      .brandStoresGetBrandStores()
      .then((response) => {
        console.log(response);
        setOriginalItems(
          response.data.map((store) => ({
            ...store,
            brandName:
              store.brand?.name[i18n.language as TranslatedStringIndex] ??
              'brand name error',
          }))
        );
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  if (authorizations && user) {
    return (
      <Has authorizations={['brand_stores:list']} showNoAuthorization>
        {user.manufacturerId || user.isProductOwner ? (
          currentItems ? (
            <Stage>
              <Navigator title={t('title')}></Navigator>
              <Card bigScreenWidth="full">
                <CardSection>
                  <List
                    items={currentItems}
                    ignore={[
                      'id',
                      'brandId',
                      'brand',
                      'updatedAt',
                      'shopReferenceId',
                      'primaryColorHex',
                      'secondaryColorHex',
                      'primaryContrastColorHex',
                      'secondaryContrastColorHex',
                      'manufacturerId',
                      'claim',
                      'description',
                      'faq',
                      'heroBannerAsset',
                    ]}
                    dateStrings={['createdAt', 'syncedAt']}
                    linkedKey={'id'}
                    linkPrefix={() => '/shop/'}
                    queryKeys={['name', 'brandName']}
                    queryString={query}
                    listControls={{
                      search: listControlSearch,
                    }}
                    adjustHeightToViewport
                    tableHeadContrast
                    sortValueFunctions={{
                      brand: (shop) =>
                        shop.brand.name[i18n.language as TranslatedStringIndex],
                    }}
                  />
                  <div
                    className={'global-cardActions'}
                    style={{ marginTop: '-1px' }}
                  >
                    <Button
                      cta={t('new')}
                      action={() => link('/shop/new')}
                      look={'secondary'}
                      width="minimal"
                    />
                  </div>
                </CardSection>
              </Card>
            </Stage>
          ) : (
            <LoadingContainer />
          )
        ) : (
          <EmptyState message={t('noManufacturer')} />
        )}
      </Has>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default Shops;
