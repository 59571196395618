import './areachart.css';
import {
  AreaChart as ReAreaChart,
  Area as ReArea,
  XAxis as ReXAxis,
  Tooltip,
  ResponsiveContainer,
  YAxis as ReYAxis,
} from 'recharts';
import AreaChartYAxis from './areachartyaxis/AreaChartYAxis';
import useYAxis from './hooks/useYAxis';
import AreaChartGrid from './areachartgrid/AreaChartGrid';
import AreaChartLegend from './areachartlegend/AreaChartLegend';

export type Area = {
  dataKey: string;
  strokeColor?: string;
  fillColor?: string;
  legendName?: string;
};

export type YAxis = {
  dataKey: string;
  steps: number;
};

interface AreaChartProps {
  xAxis?: {
    dataKey: string;
    interval?: number;
    formatter?: (value: any, index: number) => string;
  };
  yAxis?: YAxis;
  toolTipFormatter?: (value: any, name: string, props: any) => any;
  toolTipLabelFormatter?: (value: string) => string;
  height?: number | string;
  width?: number | string;
  data: any[];
  margin?: {
    top: number;
    right: number;
    left: number;
    bottom: number;
  };
  areas: Area[];
}

const AreaChart: React.FC<AreaChartProps> = ({
  areas,
  xAxis,
  yAxis,
  toolTipFormatter,
  toolTipLabelFormatter,
  height,
  width,
  data,
  margin,
}) => {
  const { yAxisTicks } = useYAxis(data, yAxis);

  return (
    <div className="areaChart">
      <AreaChartLegend areas={areas} />
      <div className="areaChart-chart">
        <AreaChartGrid bottomPadding={30} />
        {yAxis ? (
          <AreaChartYAxis ticks={yAxisTicks} bottomPadding={30} />
        ) : null}
        <ResponsiveContainer width={width} height={height}>
          <ReAreaChart
            data={data}
            margin={
              margin ?? {
                top: 10,
                right: 0,
                left: 2,
                bottom: 1,
              }
            }
          >
            {yAxis ? (
              <ReYAxis
                dataKey={yAxis.dataKey}
                ticks={yAxisTicks}
                interval={'preserveStart'}
                hide
              />
            ) : null}
            {xAxis ? (
              <ReXAxis
                dataKey={xAxis.dataKey}
                interval={'preserveStart'}
                tickLine={false}
                tickFormatter={xAxis.formatter}
              />
            ) : null}
            <Tooltip
              formatter={toolTipFormatter}
              labelFormatter={toolTipLabelFormatter}
              itemStyle={{ lineHeight: 1 }}
            />
            {areas.map((area, index) => (
              <ReArea
                key={index}
                dataKey={area.dataKey}
                stroke={area.strokeColor ?? 'var(--color-primary)'}
                fill={area.fillColor ?? 'rgba(244,130,130,0.3)'}
                type={'monotoneX'}
                strokeWidth={2}
              />
            ))}
          </ReAreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default AreaChart;
