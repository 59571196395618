import { Navigator, Stage } from '../../layout/stage/Stage';
import { useTranslation } from 'react-i18next';
import Button from '../../elements/button/Button';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import { ProductBundleResponse } from '../../api/petcloudapi/api';
import { LoadingContainer } from '../../elements/loading/Loading';
import List from '../../features/list/List';
import {
  EmptyState,
  NoAuthorization,
} from '../../elements/emptystate/EmptyState';
import Popup from '../../elements/popup/Popup';
import NewBundle from '../../sections/bundles/newbundle/NewBundle';
import Card, { CardSection } from '../../elements/card/Card';
import useListRenderObjects from '../../hooks/list/useListRenderObjects';
import { useNavigate } from 'react-router-dom';
import { Has } from '../../contexts/auth/Authorization';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import useManufacturerConditions from '../../hooks/useManufacturerConditions';
import useListControlsSearch from '../../features/list/listcontrols/hooks/useListControlsSearch';

interface BundlesProps {}

const Bundles: React.FC<BundlesProps> = () => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.bundles',
  });
  const api = usePetCloudApi();
  const productBundlesApi = api.productBundlesApi();
  const errorHandler = useErrorHandler();
  const { renderArrayCount, renderBoolean } = useListRenderObjects();
  const link = useNavigate();
  const { isProductBundleAware } = useManufacturerConditions();
  const { renderManufacturer } = useListRenderObjects();
  const { listControlSearch, setOriginalItems, currentItems, query } =
    useListControlsSearch<ProductBundleResponse>(
      t('search'),
      [],
      ['name.de-DE', 'name.en-GB', 'productNumber']
    );

  const [bundles, setBundles] = useState<ProductBundleResponse[] | null>(null);
  const [popup, setPopup] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    getBundles();
  }, []);

  const getBundles = () => {
    productBundlesApi
      .productBundlesGetProductBundles()
      .then((response) => {
        console.log(response.data);
        const result = response.data.map((bundle) => {
          const { cover, name, ...rest } = bundle;
          return {
            cover: cover
              ? cover.asset[i18n.language as TranslatedStringIndex]?.uri
              : 'no uri for system language',
            name,
            ...rest,
          } as ProductBundleResponse;
        });
        setBundles(result);
        setOriginalItems(result);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const deleteBundle = (bundleId: string) => {
    setIsDeleting(true);
    productBundlesApi
      .productBundlesDeleteProductBundle(bundleId)
      .then((response) => {
        console.log(response.data);
        getBundles();
        setIsDeleting(false);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsDeleting(false);
      });
  };

  if (isProductBundleAware()) {
    if (bundles) {
      return (
        <Has authorizations={['product_bundle:list']} showNoAuthorization>
          <Stage>
            <Navigator
              title={t('title')}
              allowBackButton
              isLoading={isDeleting}
            >
              <Button
                cta={t('new')}
                look={'primary'}
                width={'minimal'}
                action={() => setPopup(true)}
              />
            </Navigator>
            {bundles.length > 0 ? (
              <Card bigScreenWidth={'full'}>
                <CardSection>
                  <List
                    ignore={[
                      'id',
                      'coverId',
                      'shopReferenceId',
                      'syncedAt',
                      'updatedAt',
                      'description',
                    ]}
                    translatedStrings={['name']}
                    linkedKey={'id'}
                    renderObjects={[
                      {
                        key: 'enabled',
                        renderMethod: renderBoolean,
                      },
                      {
                        key: 'assets',
                        renderMethod: renderArrayCount,
                      },
                      {
                        key: 'positions',
                        renderMethod: renderArrayCount,
                      },
                      {
                        key: 'manufacturerId',
                        renderMethod: renderManufacturer,
                      },
                    ]}
                    itemImgKey={'cover'}
                    dateStrings={['createdAt']}
                    items={currentItems}
                    queryString={query}
                    queryKeys={['name', 'productNumber']}
                    actions={[
                      {
                        cta: 'edit',
                        look: 'blue',
                        action: (item) => link(item.id),
                      },
                      {
                        cta: 'delete',
                        look: 'danger',
                        action: (item) => deleteBundle(item.id),
                      },
                    ]}
                    listControls={{ search: listControlSearch }}
                  />
                </CardSection>
              </Card>
            ) : (
              <EmptyState message={t('empty')} />
            )}
            <Popup toggled={popup} width={'30%'} close={() => setPopup(false)}>
              <div className={'popup-title'}>{t('newPopup.title')}</div>
              <NewBundle
                postSubmit={() => {
                  getBundles();
                  setPopup(false);
                }}
              />
            </Popup>
          </Stage>
        </Has>
      );
    } else {
      return <LoadingContainer />;
    }
  } else {
    return <NoAuthorization />;
  }
};

export default Bundles;
