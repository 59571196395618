import { SmallHint } from '../hint/Hint';
import './colorpicker.css';

interface ColorPickerProps {
  title?: string;
  hint?: string;
  colorHex?: string | null;
  onSelect: (hex: string) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  title,
  hint,
  colorHex,
  onSelect,
}) => {
  return (
    <div className="colorpicker">
      {title || hint ? (
        <div className="colorpicker-header">
          <div className="colorpicker-header-title">{title}</div>
          {hint ? <SmallHint paragraphs={[hint]} /> : null}
        </div>
      ) : null}
      <label className="colorpicker-picker">
        <input
          type="color"
          value={colorHex ?? '#ffffff'}
          className="colorpicker-input"
          onChange={(e) => {
            onSelect(e.target.value);
          }}
        />
        <div className="colorpicker-color-wrapper">
          <div
            className="colorpicker-color"
            style={{ backgroundColor: colorHex ?? '#ffffff' }}
          ></div>
        </div>
      </label>
    </div>
  );
};

export default ColorPicker;
