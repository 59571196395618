import './dropzone.css';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useState } from 'react';
import { Store } from 'react-notifications-component';
import { useTranslation } from 'react-i18next';
import {
  AssetContext,
  AssetType,
  AssetResponse,
} from '../../api/petcloudapi/api';
import Loading from '../loading/Loading';
import { ReactComponent as Upload } from '../../../assets/icon/upload.svg';
import useAssetUpload from '../../hooks/useAssetUpload';

interface DropzoneProps {
  maxFiles: number;
  callback?: (responses: AssetResponse[]) => void;
  callbackWithoutUpload?: (files: File[]) => void;
  assetContext?:
    | 'Manufacturer'
    | 'Product'
    | 'User'
    | 'Order'
    | 'Message'
    | 'Property'
    | 'Receipt'
    | 'OnBoarding'
    | 'Other';
  assetType?: 'Image' | 'Video' | 'Audio' | 'Document' | 'Unknown';
  assetFolderId?: string;
  height?: number | string;
  tiny?: boolean;
  hasPermission?: boolean;
  accept?: string;
}

export const Dropzone: React.FC<DropzoneProps> = ({
  maxFiles,
  callback,
  callbackWithoutUpload,
  assetContext,
  assetType,
  assetFolderId,
  height,
  tiny,
  hasPermission,
  accept,
}) => {
  const { t } = useTranslation();
  const { uploadAsset } = useAssetUpload();
  const [totalFiles, setTotalFiles] = useState<number | null>(null);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      handleUpload(acceptedFiles);
    },
    [assetFolderId, callback]
  );

  const handleUpload = (acceptedFiles: File[]) => {
    if (hasPermission === undefined || hasPermission) {
      const totalFilesAmount =
        maxFiles > 0
          ? acceptedFiles.length <= maxFiles
            ? acceptedFiles.length
            : maxFiles
          : acceptedFiles.length;
      setTotalFiles(totalFilesAmount);

      let responses: AssetResponse[] = [];

      acceptedFiles.forEach(async (file, i) => {
        if (maxFiles === -1 || i < maxFiles) {
          if (callback) {
            try {
              var result = await uploadAsset(file, {
                assetContext: assetContext as AssetContext,
                assetType: assetType as AssetType,
                assetFolderId: assetFolderId,
              });
              responses.push(result as AssetResponse);
              console.log(responses);
              if (responses.length === totalFilesAmount) {
                callback(responses);
                setTotalFiles(null);
                Store.addNotification({
                  message: t(
                    'components.dropzone.notifications.upload_successful'
                  ),
                  type: 'success',
                  insert: 'top',
                  container: 'top-right',
                  animationIn: ['animate__animated', 'animate__fadeIn'],
                  animationOut: ['animate__animated', 'animate__fadeOut'],
                  dismiss: {
                    duration: 5000,
                  },
                });
              }
            } catch {
              setTotalFiles(null);
            }
          }
        }
      });

      if (callbackWithoutUpload) {
        callbackWithoutUpload(acceptedFiles);
        setTotalFiles(null);
      }
    } else {
      Store.addNotification({
        message: t('errors.403'),
        type: 'warning',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
        },
      });
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <div {...getRootProps()} className="dropzone">
      <input {...getInputProps()} accept={accept} />
      {isDragActive ? (
        <div
          className="dropzone-inner"
          style={
            height
              ? { height: height, paddingTop: 0 }
              : { height: 0, paddingTop: '100%' }
          }
        >
          <div className="dropzone-inner-text">
            {t('components.dropzone.dragging')}
          </div>
        </div>
      ) : (
        <div
          className="dropzone-inner"
          style={
            height
              ? { height: height, paddingTop: 0 }
              : { height: 0, paddingTop: '100%' }
          }
        >
          {totalFiles !== null ? (
            <div className="dropzone-inner-progress">
              <Loading />
            </div>
          ) : tiny ? (
            <div className="dropzone-inner-text">
              <Upload
                fill="var(--color-border)"
                className="dropzone-inner-text-icon"
              />
            </div>
          ) : (
            <div className="dropzone-inner-text">
              <span className="dropzone-inner-text-link">
                {t('components.dropzone.upload1')}
              </span>
              {t('components.dropzone.upload2')}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Dropzone;
