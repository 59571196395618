import { useTranslation } from 'react-i18next';

const useNumberFormat = (currency?: string | null) => {
  const { i18n } = useTranslation();

  const CurrencyFormat = new Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency: currency ?? 'EUR',
  });

  const renderCurrency = (amount: number | undefined | null) => {
    if (amount) {
      return CurrencyFormat.format(amount);
    } else {
      return '0,00';
    }
  };

  const renderRoundedFloat = (float: number | undefined) => {
    if (!float) return 0;
    return Math.round(float).toLocaleString('de-DE', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  };

  return {
    renderCurrency,
    renderRoundedFloat,
  };
};

export default useNumberFormat;
