import './propertygroupedit.css';
import {
  AnimalSpeciesResponse,
  AssetResponse,
  PropertyGroupResponse,
  PropertyOptionResponse,
  SimpleProductResponse,
  UpdatePropertyOptionRequest,
} from '../../../../api/petcloudapi/api';
import List from '../../../../features/list/List';
import { EmptyState } from '../../../../elements/emptystate/EmptyState';
import Popup from '../../../../elements/popup/Popup';
import Button from '../../../../elements/button/Button';
import { Check } from '../../../../elements/selectors/Selectors';
import Dropzone from '../../../../elements/dropzone/Dropzone';
import { useState } from 'react';
import TranslatedStringIndex from '../../../../types/TranslatedStringIndex';
import { ReactComponent as Trash } from '../../../../../assets/icon/trash.svg';
import { useTranslation } from 'react-i18next';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMoveImmutable } from 'array-move';
import useNotifications from '../../../../hooks/useNotifications';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import NewPropertyOption from './newpropertyoption/NewPropertyOption';
import { renderAnimalSpecies } from '../ProductProperties';
import AnimalSpeciesEditor from '../../animalspecies/animalspecieseditor/AnimalSpeciesEditor';
import { LoadingContainer } from '../../../../elements/loading/Loading';
import ProductList from '../../../../features/productlist/ProductList';
import ToolsMenu from '../../../../features/list/listcontrols/toolsmenu/ToolsMenu';
import AnimalSpeciesBulkUpdater from '../../animalspecies/animalspeciesbulkupdater/AnimalSpeciesBulkUpdater';
import useListRenderObjects from '../../../../hooks/list/useListRenderObjects';

interface PropertyGroupEditProps {
  propertyGroup: PropertyGroupResponse;
  selectedLocale: TranslatedStringIndex;
  getProductProperties: () => void;
  availableAnimalSpecies: AnimalSpeciesResponse[];
}

const PropertyGroupEdit: React.FC<PropertyGroupEditProps> = ({
  propertyGroup,
  selectedLocale,
  getProductProperties,
  availableAnimalSpecies,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.admin.properties',
  });
  const { pushNotification } = useNotifications();
  const api = usePetCloudApi();
  const propertyOptionsApi = api.propertyOptionsApi();
  const productsApi = api.productsApi();
  const errorHandler = useErrorHandler();
  const { renderTranslatedStringInput, renderStringInput } =
    useListRenderObjects();

  const [propertyOptions, setPropertyOptions] = useState([
    ...propertyGroup.options,
  ]);
  const [selectedPropertyOptions, setSelectedPropertyOptions] = useState<
    PropertyOptionResponse[]
  >([]);
  const [optionDangerPopup, setOptionDangerPopup] = useState(false);
  const [newPropertyOptionPopup, setNewPropertyOptionPopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [animalSpeciesArray, setAnimalSpeciesArray] = useState<number | null>(
    null
  );
  const [simpleProducts, setSimpleProducts] = useState<
    SimpleProductResponse[] | 'LOADING' | null
  >(null);
  const [speciesBulkEditor, setSpeciesBulkEditor] = useState(false);

  const attachAssetToOption = (assets: AssetResponse[], index: number) => {
    const update = [...propertyOptions];
    update[index] = {
      ...update[index],
      asset: assets[0],
      assetId: assets[0].id,
    };
    setPropertyOptions(update);
  };

  const removeAssetFromOption = (index: number) => {
    const update = [...propertyOptions];
    update[index] = {
      ...update[index],
      asset: null,
      assetId: null,
    };
    setPropertyOptions(update);
  };

  const selectPropertyOption = (item: PropertyOptionResponse) => {
    const update = [...selectedPropertyOptions];
    const i = update.findIndex((option) => option.id === item.id);
    if (i !== -1) {
      update.splice(i, 1);
    } else {
      update.push(item);
    }
    setSelectedPropertyOptions([...update]);
  };

  const selectAllPropertyOptions = () => {
    setSelectedPropertyOptions([...propertyOptions]);
  };

  const onOptionListRowDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over) {
      if (active.id !== over.id) {
        const oldPosition = propertyOptions.find(
          (option) => option.id === active.id
        )?.position;
        const newPosition = propertyOptions.find(
          (option) => option.id === over.id
        )?.position;
        if (oldPosition !== undefined && newPosition !== undefined) {
          updateOptionPosition(oldPosition, newPosition);
        }
      }
    }
  };

  const updateOptionPosition = (oldPosition: number, newPosition: number) => {
    const update = [
      ...arrayMoveImmutable(propertyOptions, oldPosition, newPosition),
    ];

    const reIndexedUpdate = update.map((o, i) => {
      return {
        ...o,
        position: i,
      };
    });
    setPropertyOptions(reIndexedUpdate);
  };

  const submitUpdatedPropertyOptions = () => {
    setIsSubmitting(true);
    const promises: Promise<unknown>[] = [];
    propertyOptions.forEach((option) => {
      const ogOption = propertyGroup.options.find((o) => o.id === option.id);
      if (ogOption) {
        if (JSON.stringify(option) !== JSON.stringify(ogOption)) {
          promises.push(submitPropertyOption(option));
        }
      }
    });

    Promise.all(promises)
      .then(() => {
        setIsSubmitting(false);
        getProductProperties();
        pushNotification(t('notifications.updateOptions_successful'));
      })
      .catch(() => {
        setIsSubmitting(false);
        pushNotification(t('notifications.updateOptions_failed'), 'danger');
      });
  };

  const submitPropertyOption = (option: PropertyOptionResponse) => {
    return new Promise((resolve, reject) => {
      const request: UpdatePropertyOptionRequest = {
        ...option,
        animalSpeciesIds: option.animalSpecies?.map((x) => x.id),
      };
      propertyOptionsApi
        .propertyOptionsUpdatePropertyOption(option.id, request)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          reject(error);
        });
    });
  };

  const deleteSelectedPropertyOptions = () => {
    setIsSubmitting(true);
    const promises = selectedPropertyOptions.map((option) => {
      return deletePropertyOption(option.id);
    });

    Promise.all(promises)
      .then(() => {
        pushNotification(t('notifications.deleteOption_successful'));
        setOptionDangerPopup(false);
        setIsSubmitting(false);
        setSelectedPropertyOptions([]);
        getProductProperties();
      })
      .catch(() => {
        pushNotification(t('notifications.deleteOption_failed'), 'danger');
        setOptionDangerPopup(false);
        setIsSubmitting(false);
        setSelectedPropertyOptions([]);
        getProductProperties();
      });
  };

  const deletePropertyOption = (id: string) => {
    return new Promise((resolve, reject) => {
      propertyOptionsApi
        .propertyOptionsDeletePropertyOption(id)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          reject(error);
        });
    });
  };

  const getProductsByPropertyOptionId = (id: string) => {
    setSimpleProducts('LOADING');
    productsApi
      .productsGetSimpleProductsList(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        [id]
      )
      .then((response) => {
        console.log(response);
        setSimpleProducts(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const renderCheckBox = (
    value: boolean,
    item: any,
    index: number,
    key: 'isVariantOption' | 'isLocked'
  ) => {
    return (
      <Check
        checked={value}
        update={() => {
          const update = [...propertyOptions];
          update[index] = {
            ...update[index],
            [key]: !update[index][key],
          };
          setPropertyOptions(update);
        }}
      />
    );
  };

  const renderAsset = (asset: AssetResponse, item: any, index: number) => {
    if (asset) {
      return (
        <div className="propertyGroupEdit-list-asset">
          <img
            src={asset.uri}
            className="propertyGroupEdit-list-asset-img"
            alt="asset"
          />
          <div className="propertyGroupEdit-list-asset-actions">
            <Button
              type="icon"
              look="tertiary-filled"
              action={() => removeAssetFromOption(index)}
            >
              <Trash fill="var(--color-danger)" className="button-icon" />
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="propertyGroupEdit-list-asset">
          <Dropzone
            maxFiles={1}
            callback={(assets) => attachAssetToOption(assets, index)}
            tiny={true}
          />
        </div>
      );
    }
  };

  const lockSelectedOptions = () => {
    const update = [...propertyOptions];
    selectedPropertyOptions.forEach((option) => {
      const i = update.findIndex((o) => o.id === option.id);
      if (i !== -1) {
        update[i] = {
          ...update[i],
          isLocked: !update[i].isLocked,
        };
      }
    });
    setPropertyOptions(update);
  };

  return (
    <div className={'propertyGroupEdit'}>
      {propertyOptions.length > 0 ? (
        <List
          listControls={{
            children: (
              <ToolsMenu
                active={selectedPropertyOptions.length > 0}
                actions={[
                  {
                    cta: t('actions.bulkUpdateSpecies'),
                    ctaAlreadyTranslated: true,
                    look: 'blue',
                    action: () => setSpeciesBulkEditor(true),
                  },
                  {
                    cta: t('actions.bulkUpdateIsLocked'),
                    ctaAlreadyTranslated: true,
                    look: 'blue',
                    action: lockSelectedOptions,
                  },
                  {
                    cta: t('actions.delete'),
                    ctaAlreadyTranslated: true,
                    look: 'danger',
                    action: () => setOptionDangerPopup(true),
                  },
                ]}
              />
            ),
          }}
          key={selectedLocale}
          items={propertyOptions}
          ignore={[
            'propertyGroupId',
            'id',
            'updatedAt',
            'assetId',
            'shopReferenceId',
            'position',
            'syncedAt',
          ]}
          dateStrings={['syncedAt', 'createdAt']}
          monoSpaceStrings={['shopReferenceId']}
          selectedLocale={selectedLocale}
          selectedItems={selectedPropertyOptions}
          onSelect={selectPropertyOption}
          onSelectAll={selectAllPropertyOptions}
          onDragEnd={onOptionListRowDragEnd}
          sortValueFunctions={{
            name: (option) =>
              option.name[i18n.language as TranslatedStringIndex].toLowerCase(),
            description: (option) =>
              option.description[
                i18n.language as TranslatedStringIndex
              ].toLowerCase(),
          }}
          renderObjects={[
            {
              key: 'name',
              renderMethod: (value, item) =>
                renderTranslatedStringInput(
                  value,
                  selectedLocale,
                  propertyOptions,
                  setPropertyOptions,
                  item,
                  'name',
                  'productProperties-input'
                ),
              receiveNullValues: true,
            },
            {
              key: 'description',
              renderMethod: (value, item) =>
                renderTranslatedStringInput(
                  value,
                  selectedLocale,
                  propertyOptions,
                  setPropertyOptions,
                  item,
                  'description',
                  'productProperties-input'
                ),
              receiveNullValues: true,
            },
            {
              key: 'isVariantOption',
              renderMethod: (value, item, index) =>
                renderCheckBox(value, item, index, 'isVariantOption'),
              receiveNullValues: true,
            },
            {
              key: 'asset',
              renderMethod: renderAsset,
              receiveNullValues: true,
            },
            {
              key: 'identifier',
              renderMethod: (value, item) =>
                renderStringInput(
                  value,
                  propertyOptions,
                  setPropertyOptions,
                  item,
                  'identifier'
                ),
              stopPropagation: true,
              receiveNullValues: true,
            },
            {
              key: 'animalSpecies',
              renderMethod: (value, item, index) =>
                renderAnimalSpecies(
                  value,
                  item,
                  index,
                  (index) => setAnimalSpeciesArray(index),
                  selectedLocale
                ),
              stopPropagation: true,
              receiveNullValues: true,
            },
            {
              key: 'isLocked',
              renderMethod: (value, item, index) =>
                renderCheckBox(value, item, index, 'isLocked'),
              receiveNullValues: true,
            },
          ]}
          tableHeadContrast
          isShowingIndex
          height={'50vh'}
          actions={[
            {
              cta: t('actions.getProductsByPropertyOptionId'),
              ctaAlreadyTranslated: true,
              action: (item) => getProductsByPropertyOptionId(item.id),
              look: 'blue',
            },
          ]}
        />
      ) : (
        <EmptyState message={t('groupPopup.noOptions')} />
      )}
      <div className="global-cardActions-spaceBetween global-cardActions-postBorder propertyGroupEdit-actions">
        <Button
          cta={t('groupPopup.new')}
          width={'minimal'}
          look={'secondary'}
          action={() => setNewPropertyOptionPopup(true)}
        />
        <Button
          cta={t('actions.save')}
          look="save"
          isLoading={isSubmitting}
          action={() => submitUpdatedPropertyOptions()}
          width="minimal"
        />
      </div>
      <Popup
        width="50%"
        toggled={newPropertyOptionPopup}
        close={() => setNewPropertyOptionPopup(false)}
      >
        <div className="popup-title">{t('newOptionPopup.title')}</div>
        <NewPropertyOption
          propertyGroup={propertyGroup}
          newPosition={propertyGroup.options.length}
          postSubmit={() => {
            setNewPropertyOptionPopup(false);
            getProductProperties();
          }}
        />
      </Popup>
      <Popup
        width="30%"
        toggled={optionDangerPopup}
        close={() => setOptionDangerPopup(false)}
      >
        <div className="popup-title">{t('optionDangerPopup.title')}</div>
        <div className="propertyGroupEdit-dangerPopup-message">
          {t('optionDangerPopup.message')}
        </div>
        <Button
          width="full"
          look="danger"
          cta={t('optionDangerPopup.cta')}
          action={deleteSelectedPropertyOptions}
          isLoading={isSubmitting}
        />
      </Popup>
      {animalSpeciesArray !== null ? (
        <Popup
          toggled={true}
          width={'30%'}
          close={() => setAnimalSpeciesArray(null)}
        >
          <div className={'popup-title'}>{t('animalSpeciesPopup.title')}</div>
          <AnimalSpeciesEditor
            availableAnimalSpecies={availableAnimalSpecies}
            animalSpecies={propertyOptions[animalSpeciesArray].animalSpecies}
            onDelete={(index) => {
              const update = [...propertyOptions];
              const species = update[animalSpeciesArray].animalSpecies;
              if (species) {
                let updatedSpecies: AnimalSpeciesResponse[] = [...species];
                updatedSpecies.splice(index, 1);
                update[animalSpeciesArray] = {
                  ...update[animalSpeciesArray],
                  animalSpecies: updatedSpecies,
                };
                setPropertyOptions(update);
              }
            }}
            onSubmit={(selectedAnimalSpecies) => {
              const update = [...propertyOptions];
              const currentSpecies = update[animalSpeciesArray].animalSpecies;
              let updatedSpecies: AnimalSpeciesResponse[] = [];
              if (currentSpecies) {
                updatedSpecies = [...currentSpecies];
              }
              updatedSpecies.push(selectedAnimalSpecies);
              update[animalSpeciesArray] = {
                ...update[animalSpeciesArray],
                animalSpecies: updatedSpecies,
              };
              setPropertyOptions(update);
            }}
          />
          <div className={'global-cardActions'}>
            <Button
              cta={t('animalSpeciesPopup.cta')}
              look={'secondary'}
              width={'minimal'}
              action={() => setAnimalSpeciesArray(null)}
            />
          </div>
        </Popup>
      ) : null}
      {simpleProducts ? (
        <Popup
          toggled={true}
          width={'50%'}
          close={() => setSimpleProducts(null)}
        >
          <div className={'popup-title'}>{t('simpleProductsPopup.title')}</div>
          {simpleProducts === 'LOADING' ? (
            <LoadingContainer />
          ) : simpleProducts.length > 0 ? (
            <ProductList prefetchedProducts={simpleProducts} allowActions />
          ) : (
            <EmptyState />
          )}
        </Popup>
      ) : null}
      <Popup
        toggled={speciesBulkEditor}
        width={'30%'}
        close={() => setSpeciesBulkEditor(false)}
      >
        <AnimalSpeciesBulkUpdater
          availableAnimalSpecies={availableAnimalSpecies}
          onSubmit={(species) => {
            const update = [...propertyOptions];
            selectedPropertyOptions.forEach((option) => {
              const i = update.findIndex((o) => o.id === option.id);
              if (i !== -1) {
                update[i] = {
                  ...update[i],
                  animalSpecies: species,
                };
              }
            });
            setPropertyOptions(update);
          }}
        />
      </Popup>
    </div>
  );
};

export default PropertyGroupEdit;
