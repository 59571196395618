import './refundsummary.css';
import { AdyenPaymentRefundResponse } from '../../../../api/petcloudapi/api';
import TitleValueList from '../../../../elements/titlevaluelist/TitleValueList';
import { useTranslation } from 'react-i18next';
import useNumberFormat from '../../../../hooks/useNumberFormat';
import useDateTools from '../../../../hooks/useDateTools';
import { useUser } from '../../../../contexts/auth/User';
import RefundSummaryStatusHistory from './refundsummarystatushistory/RefundSummaryStatusHistory';

interface RefundSummaryProps {
  refund: AdyenPaymentRefundResponse;
  currency?: string | null;
}

const RefundSummary: React.FC<RefundSummaryProps> = ({ refund, currency }) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.order.refunds.detail',
  });
  const { user } = useUser();
  const { displayReadableDate } = useDateTools();
  const { renderCurrency } = useNumberFormat(currency);

  return (
    <div className={'refundSummary'}>
      <div className={'refundSummary-titleValue'}>
        <TitleValueList
          entries={[
            {
              title: t('refundAmount'),
              value: renderCurrency(refund.totalAmount),
              asHighlight: true,
            },
            {
              title: t('ownPart'),
              value: renderCurrency(
                refund.totalAmount - refund.totalCommission
              ),
              asHighlight: true,
            },
            {
              title: t('commission'),
              value: renderCurrency(refund.totalCommission),
              asHighlight: true,
            },
            {
              title: t('createdAt'),
              value: `${displayReadableDate(refund.createdAt)}`,
              asHighlight: true,
            },
          ]}
        />
      </div>
      <div className={'refundSummary-statusHistories'}>
        {refund.status ? (
          <RefundSummaryStatusHistory
            status={refund.status}
            title={t('status')}
          />
        ) : null}
        {user?.isProductOwner && refund.commissionStatus ? (
          <RefundSummaryStatusHistory
            status={refund.commissionStatus}
            title={t('commissionStatus')}
          />
        ) : null}
      </div>
    </div>
  );
};

export default RefundSummary;
