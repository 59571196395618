import { Has } from '../../contexts/auth/Authorization';
import { Navigator, Stage } from '../../layout/stage/Stage';
import Card, { CardSection } from '../../elements/card/Card';
import { useTranslation } from 'react-i18next';
import ProductValidationList from '../../sections/productvalidation/ProductValidationList';

const ProductValidation = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.productValidation',
  });

  return (
    <Has authorizations={['products:list']} showNoAuthorization>
      <Stage>
        <Navigator title={t('title')} />
        <Card bigScreenWidth="100%" noMargin>
          <CardSection>
            <ProductValidationList />
          </CardSection>
        </Card>
      </Stage>
    </Has>
  );
};

export default ProductValidation;
