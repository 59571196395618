import React, {
  memo,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import noResults from '../../../assets/img/no_search_results.svg';
import { ItemAction } from '../../elements/itemactions/ItemActions';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import { Check } from '../../elements/selectors/Selectors';
import './list.css';

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { ButtonProps } from '../../elements/button/Button';
import ListControls, {
  ListControlsProps,
  ListFilterSetting,
} from './listcontrols/ListControls';
import ToggleSwitch from '../../elements/toggleswitch/ToggleSwitch';
import { useUser } from '../../contexts/auth/User';
import { EmptyState } from '../../elements/emptystate/EmptyState';
import useLocalStorageSettings from '../../hooks/useLocalStorageSettings';
import ListPagination from './listpagination/ListPagination';
import { LoadingContainer } from '../../elements/loading/Loading';
import ListItem from './listitem/ListItem';
import useAutoHeight from './hooks/useAutoHeight';
import useMemoScroll from './hooks/useMemoScroll';
import ListHeader from './listheader/ListHeader';

type OrderByConfig = {
  key: string;
  asc: boolean;
};

export type ListRenderObject = {
  key: string;
  renderMethod: (
    value: any,
    item: any,
    index: number,
    query?: string
  ) => ReactNode;
  stopPropagation?: boolean;
  receiveNullValues?: boolean;
};

interface ListProps {
  items:
    | {
        [key: string]: any;
      }[]
    | null
    | undefined;
  onSelect?: (item: any, index: number) => void;
  onSelectAll?: (items: any[]) => void;
  onSelectTranche?: (items: any[]) => void;
  onRowClick?: (item: any) => void;
  onDragEnd?: (event: DragEndEvent) => void;
  selectedItems?: { [key: string]: any }[] | null;
  ignore?: string[];
  ignoreHeaders?: string[];
  linkedKey?: string;
  linkPrefix?: (item: any) => string;
  linkPostfix?: string;
  linkCondition?: (item: any) => boolean;
  actions?: ItemAction[];
  singleAction?: {
    actionIcon: ReactNode;
    action: (item: any, index: number) => void;
  };
  translatedStrings?: string[];
  selectedLocale?: TranslatedStringIndex;
  dateStrings?: string[];
  dateStringsFormat?: string[];
  monoSpaceStrings?: string[];
  priceKeysConfig?: {
    priceKeys: string[];
    currencySymbol?: string | null;
    currencyKey?: string;
  };
  itemImgKey?: string;
  badgeKeys?: string[];
  dropDownValues?: string[];
  dropDownConfigs?: {
    title: string;
    optionObjects?: { name: string; id: string }[];
    options?: string[];
    update: (e: React.ChangeEvent<HTMLSelectElement>, item: any) => void;
  }[];
  renderObjects?: ListRenderObject[];
  queryString?: string;
  queryKeys?: string[];
  tableHeadContrast?: boolean;
  isShowingIndex?: boolean;
  height?: number | string;
  adjustHeightToViewport?: boolean;
  adjustHeightToViewportOffset?: number;
  unselectableIds?: string[];
  unselectableIdsDisableRows?: boolean;
  isNewTabLink?: boolean;
  wrapInNewTabLink?: string[];
  memoScrollKey?: string;
  memoScrollConditionFunc?: () => boolean;
  listControls?: ListControlsProps;
  actionsBar?: {
    buttons: ButtonProps[];
  };
  sortValueFunctions?: {
    [key: string]: (value: any) => any;
  };
  orderBy?: OrderByConfig;
  ignoreColumnTranslationExceptKeys?: string[];
  pageItemCount?: number[];
  clipboardStrings?: string[];
}

const List: React.FC<ListProps> = ({
  items,
  onSelect,
  onSelectAll,
  onSelectTranche,
  onRowClick,
  onDragEnd,
  ignore,
  ignoreHeaders,
  linkedKey,
  linkPrefix,
  linkPostfix,
  linkCondition,
  actions,
  singleAction,
  translatedStrings,
  selectedLocale,
  dateStrings,
  dateStringsFormat,
  monoSpaceStrings,
  priceKeysConfig,
  selectedItems,
  itemImgKey,
  badgeKeys,
  dropDownValues,
  dropDownConfigs,
  renderObjects,
  queryString,
  queryKeys,
  tableHeadContrast,
  isShowingIndex,
  height,
  adjustHeightToViewport,
  adjustHeightToViewportOffset,
  unselectableIds,
  unselectableIdsDisableRows,
  isNewTabLink,
  wrapInNewTabLink,
  memoScrollKey,
  memoScrollConditionFunc,
  listControls,
  actionsBar,
  sortValueFunctions,
  orderBy,
  ignoreColumnTranslationExceptKeys,
  pageItemCount,
  clipboardStrings,
}) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const link = useNavigate();

  // dynamically adjust list size to viewport
  const listRef = useRef<HTMLDivElement>(null);
  const { setToggledSetting, getToggledSetting } = useLocalStorageSettings();
  const [devShowId, setDevShowId] = useState(
    getToggledSetting('setting_devShowId')
  );
  // pagination
  const [page, setPage] = useState(1);
  const [currentPageItemCount, setCurrentPageItemCount] = useState(
    pageItemCount ? pageItemCount[0] : null
  );
  const [itemsSorted, setItemsSorted] = useState<any>(null);
  const { layout } = useAutoHeight({
    isAutoHeightEnabled: adjustHeightToViewport,
    autoHeightOffset: adjustHeightToViewportOffset,
    defaultHeight: height,
    items: items,
    selectedItems: selectedItems,
    isActionsBarEnabled: !!actionsBar,
    isTrancheSelectingEnabled: !!onSelectTranche,
    listRef: listRef,
  });

  const { handleMemoScroll, handleSetMemoScrollPosition } = useMemoScroll({
    memoScrollKey: memoScrollKey,
    memoScrollConditionFunc: memoScrollConditionFunc,
    listRef: listRef,
  });

  useEffect(() => {
    setToggledSetting('setting_devShowId', devShowId);
  }, [devShowId]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 5,
      },
    })
  );

  const indexedDndItems = items?.map((item, i) => {
    return {
      id: i.toString(),
      ...item,
    };
  });

  const columns = useMemo(() => {
    if (items && items.length > 0) {
      const columns = Object.entries(items[0]).map(([key]) => {
        if (
          (!ignore?.includes(key) && key !== 'options') ||
          (devShowId && key === 'id')
        ) {
          return key;
        } else {
          return null;
        }
      });
      if (onSelect) {
        columns.unshift('select');
      }
      if (isNewTabLink && linkedKey) {
        columns.push('newTabLink');
      }
      return columns;
    } else {
      return [];
    }
  }, [items]);

  const isAllowedToLink = (item: any) => {
    if (linkedKey && item[linkedKey]) {
      if (linkCondition) {
        return linkCondition(item);
      } else {
        return true;
      }
    } else return !!onRowClick;
  };

  const renderIdListFilterSetting = () => {
    return (
      <ToggleSwitch
        toggled={devShowId}
        toggle={() => setDevShowId(!devShowId)}
        label={t('list.settingsPopup.devShowId')}
      />
    );
  };

  const getListFilterSettings = () => {
    const settings: ListFilterSetting[] =
      listControls && listControls.settings ? [...listControls.settings] : [];
    if (user?.isProductOwner) {
      settings.push({
        renderMethod: renderIdListFilterSetting,
      });
    }
    return settings.length > 0 ? settings : undefined;
  };

  const getItems = () => {
    let itemArray = itemsSorted ?? items;
    if (currentPageItemCount && page) {
      return itemArray?.slice(
        (page - 1) * currentPageItemCount,
        page * currentPageItemCount
      );
    } else {
      return itemArray;
    }
  };

  const getLinkString = (item: any) => {
    let linkString = '';
    if (linkPrefix) {
      linkString += linkPrefix(item);
    }
    if (linkedKey) {
      linkString += item[linkedKey];
    }
    if (linkPostfix) {
      linkString += linkPostfix;
    }
    return linkString;
  };

  const currentItems = getItems();

  return (
    <>
      {listControls ? (
        <ListControls {...listControls} settings={getListFilterSettings()} />
      ) : null}
      {items && layout.showActionsBar ? (
        <div className={'list-actionsBar'}>
          {onSelectAll && onSelectTranche ? (
            <div className={'list-actionsBar-check'}>
              <Check
                checked={selectedItems?.length === items?.length}
                halfChecked={true}
                text={`${selectedItems?.length} ${t(
                  'list.actionsBar.selectedItems'
                )}`}
                textColor={'var(--color-green)'}
                update={() => onSelectAll(items)}
              />
            </div>
          ) : null}
          {actionsBar ? actionsBar.buttons : null}
        </div>
      ) : null}
      {items && indexedDndItems ? (
        items.length > 0 ? (
          <>
            <div
              className="list"
              ref={listRef}
              onLoadCapture={handleSetMemoScrollPosition}
              style={{ maxHeight: layout.listHeight ?? 'auto' }}
              onScroll={(s) => {
                const target = s.target as HTMLDivElement;
                handleMemoScroll(target.scrollTop);
              }}
            >
              <table
                className={`list-table ${
                  onDragEnd ? 'list-table__widthDrag' : ''
                }`}
              >
                <tbody>
                  <ListHeader
                    items={items}
                    selectedItems={selectedItems}
                    pageItems={currentItems}
                    columns={columns}
                    tableHeadContrast={tableHeadContrast}
                    isShowingIndex={isShowingIndex}
                    onSelectAll={onSelectAll}
                    onSelectTranche={onSelectTranche}
                    itemImgKey={itemImgKey}
                    ignoreHeaders={ignoreHeaders}
                    ignoreColumnTranslationExceptKeys={
                      ignoreColumnTranslationExceptKeys
                    }
                    hasActions={!!actions || !!singleAction}
                    orderBy={orderBy}
                    setItemsSorted={setItemsSorted}
                    sortValueFunctions={sortValueFunctions}
                  />
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={onDragEnd ?? undefined}
                  >
                    <SortableContext
                      items={indexedDndItems}
                      strategy={rectSortingStrategy}
                    >
                      {currentItems.map((item: any, i: number) => {
                        let className = 'list-table-tr';
                        if (isAllowedToLink(item) || onDragEnd) {
                          className = className += ' list-table-tr__linked';
                        }
                        if (getToggledSetting('interface_compactList')) {
                          className = className += ' list-table-tr__compact';
                        }

                        const linkString = getLinkString(item);

                        const returnListItem = () => {
                          return (
                            <ListItem
                              key={JSON.stringify(item) + i}
                              index={i}
                              showIndex={isShowingIndex}
                              columns={columns}
                              item={item}
                              onClick={
                                isAllowedToLink(item) && linkedKey
                                  ? () => link(linkString)
                                  : onRowClick
                                  ? () => onRowClick(item)
                                  : undefined
                              }
                              newTabLink={
                                isNewTabLink && isAllowedToLink(item)
                                  ? linkString
                                  : null
                              }
                              onDragEnd={onDragEnd}
                              onSelect={onSelect}
                              selectedItems={selectedItems}
                              actions={actions}
                              action={singleAction}
                              translatedStrings={translatedStrings}
                              selectedLocale={selectedLocale}
                              dateStrings={dateStrings}
                              dateStringsFormat={dateStringsFormat}
                              monoSpaceStrings={monoSpaceStrings}
                              priceKeysConfig={priceKeysConfig}
                              itemImgKey={itemImgKey}
                              badgeKeys={badgeKeys}
                              dropDownValues={dropDownValues}
                              dropDownConfigs={dropDownConfigs}
                              renderObjects={renderObjects}
                              className={className}
                              queryString={queryString}
                              queryKeys={queryKeys}
                              unselectable={unselectableIds?.includes(item.id)}
                              unselectableIdsDisableRows={
                                unselectableIdsDisableRows
                              }
                              wrapInNewTabLink={wrapInNewTabLink}
                              clipboardStrings={clipboardStrings}
                            />
                          );
                        };
                        return returnListItem();
                      })}
                    </SortableContext>
                  </DndContext>
                </tbody>
              </table>
            </div>
            {pageItemCount && currentPageItemCount && page ? (
              <ListPagination
                totalItemCount={items.length}
                pageItemCount={pageItemCount}
                currentPageItemCount={currentPageItemCount}
                setCurrentPageItemCount={setCurrentPageItemCount}
                page={page}
                setPage={setPage}
              />
            ) : null}
          </>
        ) : (
          <EmptyState img={noResults} />
        )
      ) : (
        <LoadingContainer />
      )}
    </>
  );
};

const deepCompare = (prevProps: ListProps, nextProps: ListProps) => {
  return (
    prevProps.items === nextProps.items &&
    prevProps.selectedItems === nextProps.selectedItems &&
    prevProps.selectedLocale === nextProps.selectedLocale &&
    prevProps.queryString === nextProps.queryString &&
    prevProps.actionsBar === nextProps.actionsBar &&
    prevProps.listControls === nextProps.listControls
  );
};

export default memo(List, deepCompare);

export const states: { [key: string]: string } = {
  Active: 'var(--color-success)',
  Processed: 'var(--color-success)',
  Completed: 'var(--color-success)',
  Returned: 'var(--color-inherited)',
  Shipped: 'var(--color-success)',
  Closed: 'var(--color-success)',
  Booked: 'var(--color-success)',
  Refunded: 'var(--color-inherited)',
  Paid: 'var(--color-success)',
  Pending: 'var(--color-gray)',
  pending: 'var(--color-gray)',
  Archived: 'var(--color-gray)',
  Undefined: 'var(--color-gray)',
  Validated: 'var(--color-blue)',
  Draft: 'var(--color-blue)',
  Open: 'var(--color-blue)',
  Authorized: 'var(--color-yellow)',
  new: 'var(--color-primary)',
  NeedsValidation: 'var(--color-primary)',
  ForApproval: 'var(--color-primary)',
  InProcess: 'var(--color-yellow)',
  InProgress: 'var(--color-yellow)',
  InValidation: 'var(--color-yellow)',
  Inactive: 'var(--color-yellow)',
  Review: 'var(--color-yellow)',
  RefundedPartially: 'var(--color-inherited)',
  ReturnedPartially: 'var(--color-inherited)',
  ShippedPartially: 'var(--color-yellow)',
  Failed: 'var(--color-danger)',
  Cancelled: 'var(--color-danger)',
};
