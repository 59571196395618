import { useMemo } from 'react';
import { YAxis } from '../AreaChart';

const useYAxis = (data: any[], yAxis?: YAxis) => {
  const roundToMax = (num: number) => {
    let length = num.toString().length;

    if (length === 1) {
      return 10;
    }

    let place = Math.pow(10, length - 2);
    return Math.ceil(num / place) * place;
  };

  const yAxisTicks = useMemo((): number[] => {
    if (yAxis) {
      const values = data.map((item) => item[yAxis.dataKey]);
      const maxValue = parseInt(Math.max(...values).toString());
      const max = roundToMax(maxValue);
      const stepValue = max / (yAxis.steps - 1);
      return Array.from({ length: yAxis.steps }, (_, i) =>
        Math.round(i * stepValue)
      );
    } else {
      return [0, 1];
    }
  }, [data]);

  return {
    yAxisTicks,
  };
};

export default useYAxis;
