import './areachartlegend.css';
import { Area } from '../AreaChart';

interface AreaChartLegendProps {
  areas: Area[];
}

const AreaChartLegend: React.FC<AreaChartLegendProps> = ({ areas }) => {
  return (
    <div className="areaChartLegend">
      {areas.map((area, i) => (
        <div key={i} className={'areaChartLegend-entry'}>
          <div
            className={'areaChartLegend-entry-color'}
            style={{
              backgroundColor: area.strokeColor ?? 'var(--color-primary)',
            }}
          ></div>
          <div className={'areaChartLegend-entry-label'}>
            {area.legendName ?? 'missing name'}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AreaChartLegend;
