import './searchbar.css';
import icon from '../../../assets/icon/search.svg';
import { ReactComponent as IconSettings } from '../../../assets/icon/settings.svg';
import { ReactComponent as IconRemove } from '../../../assets/icon/close.svg';
import { ReactComponent as IconReset } from '../../../assets/icon/reload.svg';
import useDebounce from '../../hooks/useDebounce';
import Button from '../button/Button';
import { useEffect, useState } from 'react';

interface props {
  cta: string;
  height: 'small' | 'normal';
  onDebouncedSearch?: (input: string) => void;
  onSearch?: (input: string) => void;
  initialValue?: string;
  value?: string;
  invertedColor?: boolean;
  onSettingsButtonClick?: () => void;
  onErase?: () => void;
  onReset?: () => void;
  isError?: boolean;
}

const Searchbar: React.FC<props> = ({
  cta,
  height,
  onDebouncedSearch,
  onSearch,
  initialValue,
  value,
  invertedColor,
  onSettingsButtonClick,
  isError,
  onErase,
  onReset,
}) => {
  const debounced = useDebounce(onDebouncedSearch);

  const [query, setQuery] = useState<string | null>(value ?? null);

  useEffect(() => {
    if (query !== null) {
      if (onDebouncedSearch) {
        debounced(() => onDebouncedSearch(query));
      } else if (onSearch) {
        onSearch(query);
      }
    }
  }, [query]);

  return (
    <div className="searchbar-wrapper">
      <input
        type="text"
        className={`searchbar ${isError ? 'searchbar__error' : ''}`}
        placeholder={cta}
        style={{
          height: height === 'small' ? '30px' : '40px',
          backgroundColor: invertedColor
            ? 'var(--color-foreground)'
            : 'var(--color-background_light)',
        }}
        onChange={(e) => setQuery(e.target.value)}
        value={query ?? initialValue}
      ></input>
      <img
        src={icon}
        alt="Search"
        className="searchbar-icon"
        style={{ height: height === 'small' ? '14px' : '20px' }}
      />
      <div className="searchbar-actions">
        {query && query !== '' ? (
          <div className={'searchbar-action'}>
            <Button
              width={'tiny'}
              look={'secondary'}
              type={'icon'}
              action={() => {
                setQuery('');
                if (onErase) onErase();
              }}
            >
              <IconRemove
                className={'searchbar-action-icon button-icon-danger'}
              />
            </Button>
          </div>
        ) : null}
        {onReset ? (
          <div className={'searchbar-action'}>
            <Button
              width={'tiny'}
              look={'secondary'}
              type={'icon'}
              action={() => {
                setQuery('');
                onReset();
              }}
            >
              <IconReset className={'button-icon-tiny button-icon-secondary'} />
            </Button>
          </div>
        ) : null}
        {onSettingsButtonClick ? (
          <div className={'searchbar-action'}>
            <Button
              width={'tiny'}
              look={'secondary'}
              type={'icon'}
              action={onSettingsButtonClick}
            >
              <IconSettings
                className={'button-icon-tiny button-icon-secondary'}
              />
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Searchbar;
