import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { Stage, Navigator } from '../../layout/stage/Stage';
import List from '../../features/list/List';
import { LoadingContainer } from '../../elements/loading/Loading';
import Button from '../../elements/button/Button';
import { useNavigate } from 'react-router-dom';
import Card, { CardSection } from '../../elements/card/Card';
import MinifiedBrand from '../../types/MinifiedBrand';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import { Store } from 'react-notifications-component';
import { Has, useAuthorization } from '../../contexts/auth/Authorization';
import useManufacturerOptions from '../../hooks/useManufacturerOptions';
import useListRenderObjects from '../../hooks/list/useListRenderObjects';
import useListControlsSearch from '../../features/list/listcontrols/hooks/useListControlsSearch';

const Brands = () => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.brands',
  });
  const api = usePetCloudApi();
  const brandsApi = api.brandsApi();
  const errorHandler = useErrorHandler();
  const link = useNavigate();
  const { authorizations } = useAuthorization();
  const { manufacturerDict } = useManufacturerOptions();
  const { renderManufacturer, renderBoolean } = useListRenderObjects();
  const { listControlSearch, query, currentItems, setOriginalItems } =
    useListControlsSearch(t('search_cta'), null, [
      'slug',
      'name.de-DE',
      'name.en-GB',
    ]);

  useEffect(() => {
    getBrands();
  }, [manufacturerDict]);

  const getBrands = () => {
    brandsApi
      .brandsGetBrands()
      .then((response) => {
        console.log(response);
        const dto = response.data.map((brand) => {
          const logo = brand.logo;
          return {
            id: brand.id,
            logoUri: logo
              ? logo[i18n.language as TranslatedStringIndex]?.uri
              : undefined,
            name: brand.name,
            slug: brand.slug,
            claim: brand.claim,
            isActiveInStorefront: brand.isActiveInStorefront,
            manufacturer:
              manufacturerDict && manufacturerDict !== 'NO_PERMISSION'
                ? manufacturerDict[brand.manufacturerId]
                : undefined,
            createdAt: brand.createdAt,
            syncedAt: brand.syncedAt,
          };
        });
        setOriginalItems(dto);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const deleteBrand = (brandId: string) => {
    brandsApi
      .brandsDeleteBrand(brandId)
      .then((response) => {
        console.log(response);
        Store.addNotification({
          message: t('notifications.delete_successful'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
          },
        });
        getBrands();
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  if (authorizations) {
    return (
      <Has authorizations={['brands:list']} showNoAuthorization>
        {currentItems ? (
          <Stage>
            <Navigator title={t('title')}>
              <Button
                cta={t('new')}
                action={() => link('/brands/new')}
                look="primary"
                width="minimal"
                hasPermission={authorizations.includes('brands:create')}
              />
            </Navigator>
            <Card bigScreenWidth="full" noMargin>
              <CardSection>
                <List
                  items={currentItems}
                  translatedStrings={['name', 'claim']}
                  itemImgKey={'logoUri'}
                  ignore={['id']}
                  dateStrings={['createdAt', 'syncedAt']}
                  renderObjects={[
                    {
                      key: 'isActiveInStorefront',
                      renderMethod: renderBoolean,
                      receiveNullValues: true,
                    },
                    {
                      key: 'manufacturer',
                      renderMethod: renderManufacturer,
                    },
                  ]}
                  actions={[
                    {
                      cta: 'edit',
                      look: 'blue',
                      action: (item) => link('/brands/' + item.id),
                    },
                    {
                      cta: 'delete',
                      look: 'danger',
                      action: (item) => deleteBrand(item.id),
                    },
                  ]}
                  queryKeys={['name', 'slug']}
                  queryString={query}
                  listControls={{
                    search: listControlSearch,
                  }}
                  sortValueFunctions={{
                    name: (brand: MinifiedBrand) => {
                      return brand.name[
                        i18n.language as TranslatedStringIndex
                      ]?.toLowerCase();
                    },
                  }}
                  linkedKey={'id'}
                  linkPrefix={() => '/brands/'}
                  isNewTabLink
                  wrapInNewTabLink={['name']}
                  adjustHeightToViewport
                  tableHeadContrast
                />
              </CardSection>
            </Card>
          </Stage>
        ) : (
          <LoadingContainer />
        )}
      </Has>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default Brands;
