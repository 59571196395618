import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import {
  CreateOrderShippingDetailRequest,
  OrderPositionResponse,
  OrderResponse,
} from '../../../../api/petcloudapi/api';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import './createshippingdetail.css';
import useNotifications from '../../../../hooks/useNotifications';
import ShippingDetailEdit from '../shippingdetailedit/ShippingDetailEdit';

const newRequest: CreateOrderShippingDetailRequest = {
  shippingProviderId: 'null',
  trackingCode: null,
  weight: null,
  transmissionDate: null,
  shippingDateEarliest: null,
  shippingDateLatest: null,
  positions: [],
  notifyCustomer: true,
};

interface CreateShippingDetailProps {
  order: OrderResponse;
  orderPositions: OrderPositionResponse[];
  refreshOrder: () => void;
  closePopup: () => void;
}

const CreateShippingDetail: React.FC<CreateShippingDetailProps> = ({
  order,
  orderPositions,
  refreshOrder,
  closePopup,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.order.shippingDetails.create',
  });
  const { pushNotification } = useNotifications();
  const api = usePetCloudApi();
  const orderShippingDetailsApi = api.orderShippingDetailsApi();
  const errorHandler = useErrorHandler();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitNewShippingDetail = (
    request: CreateOrderShippingDetailRequest
  ) => {
    setIsSubmitting(true);
    orderShippingDetailsApi
      .orderShippingDetailsCreateOrderShippingDetail(request)
      .then((response) => {
        console.log(response);
        setIsSubmitting(false);
        pushNotification(t('notifications.success'));
        refreshOrder();
        closePopup();
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  return (
    <div className="createShippingDetail">
      <ShippingDetailEdit
        order={order}
        orderPositions={orderPositions}
        shippingDetail={newRequest}
        submit={submitNewShippingDetail}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default CreateShippingDetail;
