import { DragEndEvent } from '@dnd-kit/core';
import ItemActions, {
  ItemAction,
} from '../../../elements/itemactions/ItemActions';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import useNumberFormat from '../../../hooks/useNumberFormat';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import LazyImage from '../../../elements/lazyimage/LazyImage';
import { displayStringHighlighted } from '../../../elements/productsearch/ProductSearch';
import DateTime from '../../../elements/datetime/DateTime';
import Badge from '../../../elements/badge/Badge';
import { Check, Dropdown } from '../../../elements/selectors/Selectors';
import Button from '../../../elements/button/Button';
import { states } from '../List';
import { ReactComponent as ImagePlaceholder } from '../../../../assets/icon/image.svg';
import { ReactComponent as LinkIcon } from '../../../../assets/icon/link.svg';
import { ReactComponent as IconDrag } from '../../../../assets/icon/drag.svg';
import { ReactComponent as IconCopy } from '../../../../assets/icon/copy.svg';

interface ListItemProps {
  index: number;
  showIndex?: boolean;
  item: { [key: string]: any };
  columns: (string | null)[];
  onClick?: () => void;
  onDragEnd?: (event: DragEndEvent) => void;
  className?: string;
  onSelect?: (item: any, index: number) => void;
  selectedItems?: { [key: string]: any }[] | null;
  actions?: ItemAction[];
  action?: {
    actionIcon: ReactNode;
    action: (item: any, index: number) => void;
  };
  translatedStrings?: string[];
  selectedLocale?: TranslatedStringIndex;
  dateStrings?: string[];
  dateStringsFormat?: string[];
  monoSpaceStrings?: string[];
  priceKeysConfig?: {
    priceKeys: string[];
    currencySymbol?: string | null;
    currencyKey?: string;
  };
  itemImgKey?: string;
  badgeKeys?: string[];
  dropDownValues?: string[];
  dropDownConfigs?: {
    title: string;
    optionObjects?: { name: string; id: string }[];
    options?: string[];
    update: (e: React.ChangeEvent<HTMLSelectElement>, item: any) => void;
  }[];
  renderObjects?: {
    key: string;
    renderMethod: (
      value: any,
      item: any,
      index: number,
      query?: string
    ) => React.ReactNode;
    stopPropagation?: boolean;
    receiveNullValues?: boolean;
  }[];
  queryString?: string;
  queryKeys?: string[];
  unselectable?: boolean;
  unselectableIdsDisableRows?: boolean;
  newTabLink?: string | null;
  wrapInNewTabLink?: string[];
  clipboardStrings?: string[];
}

const ListItem: React.FC<ListItemProps> = ({
  index,
  showIndex,
  item,
  columns,
  onClick,
  onDragEnd,
  onSelect,
  selectedItems,
  translatedStrings,
  priceKeysConfig,
  actions,
  action,
  selectedLocale,
  dateStrings,
  dateStringsFormat,
  monoSpaceStrings,
  itemImgKey,
  badgeKeys,
  dropDownValues,
  dropDownConfigs,
  renderObjects,
  className,
  queryString,
  queryKeys,
  unselectable,
  unselectableIdsDisableRows,
  newTabLink,
  wrapInNewTabLink,
  clipboardStrings,
}) => {
  const { t, i18n } = useTranslation();
  const { renderCurrency } = useNumberFormat('EUR');

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: item.id,
    disabled: !onDragEnd,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 999 : 1,
    boxShadow: isDragging ? 'var(--shadow-wide)' : 'none',
  };

  const isSelected = (selection: { [key: string]: any }, item: any) => {
    const selected = selection.find((i: any) => {
      return JSON.stringify(i) === JSON.stringify(item) || i.id === item.id;
    });
    return !!selected;
  };

  const wrapValueInNewTabLink = (
    element: JSX.Element | ReactNode,
    key: string
  ) => {
    if (newTabLink && wrapInNewTabLink?.includes(key)) {
      return <a href={newTabLink}>{element}</a>;
    } else {
      return element;
    }
  };

  const renderCell = (key: string, value: any) => {
    const renderObject = renderObjects?.find((obj) => obj.key === key);
    if (renderObject) {
      return (
        <div
          key={key + value}
          className="list-table-td"
          onClick={(e) => {
            if (renderObject.stopPropagation) {
              e.stopPropagation();
            }
          }}
          onMouseDown={(e) => {
            if (renderObject.stopPropagation) {
              e.stopPropagation();
            }
          }}
        >
          {value || renderObject.receiveNullValues ? (
            wrapValueInNewTabLink(
              renderObject.renderMethod(value, item, index, queryString),
              key
            )
          ) : (
            <div className={'list-table-td-na'}>-</div>
          )}
        </div>
      );
    }
    if (key === itemImgKey) {
      if (value) {
        return (
          <div key={key} className="list-table-td-img">
            <LazyImage
              src={value}
              className={'list-table-td-img-img'}
              alt={'Thumbnail'}
            />
          </div>
        );
      } else {
        return (
          <div key={key} className="list-table-td-img">
            <ImagePlaceholder
              className="list-table-td-img-imgPlaceholder"
              fill={'var(--color-border)'}
            />
          </div>
        );
      }
    }
    if (translatedStrings?.includes(key)) {
      const locale = selectedLocale ? selectedLocale : i18n.language;
      return (
        <div key={key} className="list-table-td">
          {wrapValueInNewTabLink(
            queryKeys?.includes(key)
              ? displayStringHighlighted(value[locale], queryString)
              : value[locale] ?? <div className={'list-table-td-na'}>-</div>,
            key
          )}
        </div>
      );
    }
    if (dateStrings?.includes(key)) {
      return (
        <div key={key} className="list-table-td">
          {value ? (
            <div className={'list-table-td-monoSpaced'}>
              <DateTime
                dateString={value}
                show={
                  dateStringsFormat ?? [
                    'weekday',
                    'day',
                    'month',
                    'year',
                    'time',
                  ]
                }
                shortDays={true}
                shortMonths={true}
              />
            </div>
          ) : null}
        </div>
      );
    }
    if (monoSpaceStrings?.includes(key) || key === 'id') {
      return (
        <div key={key} className="list-table-td">
          {value ? (
            <div
              className="list-table-td-monoSpaced"
              onClick={(e) => e.stopPropagation()}
            >
              {wrapValueInNewTabLink(
                queryKeys?.includes(key)
                  ? displayStringHighlighted(value, queryString)
                  : value,
                key
              )}
            </div>
          ) : (
            <div className={'list-table-td-na'}>-</div>
          )}
        </div>
      );
    }
    if (priceKeysConfig && priceKeysConfig.priceKeys.includes(key)) {
      return (
        <div key={key} className="list-table-td">
          {value ? (
            renderCurrency(value)
          ) : (
            <div className={'list-table-td-na'}>-</div>
          )}
        </div>
      );
    }
    if (badgeKeys?.includes(key) && value) {
      return (
        <div key={key} className="list-table-td">
          <div className="list-table-td-flex">
            <Badge title={t('list.states.' + value)} color={states[value]} />
          </div>
        </div>
      );
    }
    if (dropDownValues?.includes(key)) {
      const dropDownConfig = dropDownConfigs?.find(
        (config) => config.title === key
      );
      if (dropDownConfig) {
        let selected;
        if (dropDownConfig.optionObjects) {
          selected = dropDownConfig.optionObjects.find(
            (option) => option.id === value
          )?.name;
        } else if (dropDownConfig.options) {
          selected = dropDownConfig.options.find((option) => option === value);
        }
        return (
          <div key={key} className="list-table-td">
            <div className="list-table-td-flex">
              <Dropdown
                options={dropDownConfig.options ?? undefined}
                optionObjects={dropDownConfig.optionObjects ?? undefined}
                selected={selected}
                update={(e) => dropDownConfig.update(e, item)}
                required={true}
              />
            </div>
          </div>
        );
      }
    }
    return wrapValueInNewTabLink(
      queryKeys?.includes(key) ? (
        displayStringHighlighted(
          value !== null && value !== undefined ? (
            value.toString()
          ) : (
            <div className={'list-table-td-na'}>-</div>
          ),
          queryString
        )
      ) : value !== null && value !== undefined ? (
        value.toString()
      ) : (
        <div className={'list-table-td-na'}>-</div>
      ),
      key
    );
  };

  return (
    <tr
      onClick={onClick}
      className={`${className} ${
        unselectable && unselectableIdsDisableRows
          ? 'list-table-tr-disabled'
          : null
      }`}
      ref={setNodeRef}
      style={onDragEnd ? style : undefined}
      {...attributes}
      {...listeners}
    >
      {showIndex ? (
        <td className={'list-table-td'}>
          <div className={'list-table-td-index'}>
            {onDragEnd ? (
              <IconDrag className={'list-table-td-index-drag'} />
            ) : null}
            {index + 1}
          </div>
        </td>
      ) : null}
      {onSelect && selectedItems ? (
        <td
          className="list-table-td-selector"
          onClick={
            !unselectable
              ? (e) => {
                  e.stopPropagation();
                  onSelect(item, index);
                }
              : undefined
          }
          onMouseDown={
            !unselectable
              ? (e) => {
                  e.stopPropagation();
                }
              : undefined
          }
        >
          <Check
            checked={isSelected(selectedItems, item)}
            disabled={unselectable}
          />
        </td>
      ) : null}
      {Object.entries(item).map(([key, value], index) => {
        if (columns.includes(key)) {
          return (
            <td key={index}>
              <div className={'list-table-td-flex'}>
                {renderCell(key, value)}
                {clipboardStrings?.includes(key) ? (
                  <Button
                    type={'icon'}
                    width={'tiny'}
                    look={'tertiary'}
                    action={() => {
                      navigator.clipboard.writeText(value);
                    }}
                    helperCSSClass={'list-table-td-clipboard'}
                  >
                    <IconCopy className={'button-icon button-icon-tertiary'} />
                  </Button>
                ) : null}
              </div>
            </td>
          );
        } else {
          return null;
        }
      })}
      {newTabLink ? (
        <td>
          <a
            className={'list-table-td-newTabLink'}
            href={newTabLink}
            target={'_blank'}
            rel="noreferrer"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <LinkIcon
              className={'list-table-td-newTabLink-icon'}
              fill={'var(--color-text_tertiary)'}
            />
          </a>
        </td>
      ) : null}
      {actions ? (
        <td className="list-table-td-actions">
          <ItemActions item={item} actions={actions} />
        </td>
      ) : action ? (
        <td className="list-table-td-actions">
          <Button
            type={'icon'}
            action={() => action.action(item, index)}
            look={'secondary'}
          >
            {action.actionIcon}
          </Button>
        </td>
      ) : null}
    </tr>
  );
};

export default ListItem;
