import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListFilterProps } from '../listfilter/ListFilter';
import useUrlParams from '../../../../hooks/useUrlParams';
import { DropdownOption } from '../../../../elements/selectors/Selectors';
import useAnimalSpeciesOptions from '../../../../hooks/useAnimalSpeciesOptions';

export type AnimalSpeciesListFilterOptions = {
  presetAnimalSpeciesOptions?: DropdownOption[] | null;
  noAllSpecies?: boolean;
};

const useAnimalSpeciesListFilter = (opts?: AnimalSpeciesListFilterOptions) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.useListFilters',
  });
  const { setUrlParam, removeUrlParam, getUrlParam } = useUrlParams();
  const { animalSpeciesOptions } = useAnimalSpeciesOptions({
    noAllSpecies: opts?.noAllSpecies,
    presetAnimalSpeciesOptions: opts?.presetAnimalSpeciesOptions,
  });

  const getAnimalSpeciesFromURLParams = () => {
    const animalSpeciesParam = getUrlParam('animalSpeciesIds');
    if (animalSpeciesParam && animalSpeciesParam !== '') {
      const result = animalSpeciesParam.split('_');
      result.pop();
      return result;
    } else {
      return null;
    }
  };

  const [selectedAnimalSpeciesIds, setSelectedAnimalSpeciesIds] = useState<
    string[] | null
  >(getAnimalSpeciesFromURLParams());

  const animalSpeciesFilter: ListFilterProps | undefined = useMemo(() => {
    const options = opts?.presetAnimalSpeciesOptions ?? animalSpeciesOptions;

    const selected = opts?.presetAnimalSpeciesOptions
      ? opts?.presetAnimalSpeciesOptions
          .filter((x) => selectedAnimalSpeciesIds?.includes(x.id))
          .map((x) => x.name)
      : animalSpeciesOptions
      ? animalSpeciesOptions
          .filter((x) => selectedAnimalSpeciesIds?.includes(x.id))
          .map((x) => x.name)
      : [];

    return options
      ? {
          optionObjects: options,
          multiSelected: selected,
          update: (option) => {
            console.log(option);
            const update = selectedAnimalSpeciesIds
              ? [...selectedAnimalSpeciesIds]
              : [];
            const i = update.findIndex((x) => x === option.id);
            if (i > -1) {
              update.splice(i, 1);
            } else {
              update.push(option.id);
            }
            setSelectedAnimalSpeciesIds(update);
            setUrlParam('animalSpeciesIds', update.join('_') + '_');
            if (option.id === 'all') {
              removeUrlParam('animalSpeciesIds');
            }
          },
          defaultOptionText: t('allAnimalSpecies'),
          onClearAll: () => {
            removeUrlParam('animalSpeciesIds');
            setSelectedAnimalSpeciesIds([]);
          },
        }
      : undefined;
  }, [animalSpeciesOptions, selectedAnimalSpeciesIds]);

  return {
    animalSpeciesFilter,
    selectedAnimalSpeciesIds,
  };
};

export default useAnimalSpeciesListFilter;
