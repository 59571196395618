import './areachartgrid.css';

interface AreaChartGridProps {
  bottomPadding?: number;
}

const AreaChartGrid: React.FC<AreaChartGridProps> = ({ bottomPadding }) => {
  return (
    <div
      className={'areaChartGrid'}
      style={bottomPadding ? { bottom: bottomPadding } : undefined}
    ></div>
  );
};

export default AreaChartGrid;
