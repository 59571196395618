const useLocalStorageSettings = () => {
  const setToggledSetting = (name: string, bool: boolean) => {
    localStorage.setItem(name, bool.toString());
  };

  const getToggledSetting = (name: string) => {
    const bool = localStorage.getItem(name);
    if (bool && bool === 'true') {
      return true;
    } else if (bool && bool === 'false') {
      return false;
    } else {
      return false;
    }
  };

  const setSetting = (name: string, value: string) => {
    localStorage.setItem(name, value);
  };

  const getSetting = (name: string) => {
    const value = localStorage.getItem(name);
    if (value) {
      return value;
    } else {
    }
  };

  const getNumericSetting = (name: string) => {
    const value = localStorage.getItem(name);
    if (value) {
      return parseInt(value);
    } else {
      return undefined;
    }
  };

  const deleteSetting = (name: string) => {
    localStorage.removeItem(name);
  };

  return {
    setToggledSetting,
    getToggledSetting,
    setSetting,
    getSetting,
    getNumericSetting,
    deleteSetting,
  };
};

export default useLocalStorageSettings;
