import './hexcolorasset.css';
import {
  AssetContext,
  AssetResponse,
} from '../../../../../../api/petcloudapi/api';
import ColorPicker from '../../../../../../elements/colorpicker/ColorPicker';
import Button from '../../../../../../elements/button/Button';
import { ReactComponent as IconSettings } from '../../../../../../../assets/icon/settings.svg';
import { ReactComponent as IconTrash } from '../../../../../../../assets/icon/trash.svg';
import { ReactNode, useEffect, useRef, useState } from 'react';
import Popup from '../../../../../../elements/popup/Popup';
import { useTranslation } from 'react-i18next';
import useAssetUpload from '../../../../../../hooks/useAssetUpload';

type ColorConfig = {
  color1?: string | null;
  color2?: string | null;
  color3?: string | null;
};

interface HexColorAssetProps {
  hex?: string | null;
  onHexChange: (hex: string) => void;
  onAssetUpload: (assets: AssetResponse[]) => void;
  hasAsset: boolean;
}

const HexColorAsset: React.FC<HexColorAssetProps> = ({
  hex,
  onHexChange,
  onAssetUpload,
  hasAsset,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.admin.properties.newOptionPopup.hexColorAsset',
  });
  const { uploadAsset } = useAssetUpload();
  const [popup, setPopup] = useState(false);
  const [colorConfig, setColorConfig] = useState<ColorConfig>({
    color1: hex,
    color2: undefined,
    color3: undefined,
  });

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setColorConfig({
      ...colorConfig,
      color1: hex,
    });
  }, [hex]);

  useEffect(() => {
    if (popup) {
      updateCanvas();
    }
  }, [popup, colorConfig]);

  const updateCanvas = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        const colors = Object.values(colorConfig).filter(
          (color) => color !== undefined
        ) as string[];
        if (colors.length === 1) {
          ctx.fillStyle = colors[0];
          ctx.fillRect(0, 0, canvas.width, canvas.height);
        } else if (colors.length >= 2) {
          // Draw the first half with the first color
          ctx.fillStyle = colors[0];
          ctx.beginPath();
          ctx.moveTo(0, 0);
          ctx.lineTo(canvas.width, 0);
          ctx.lineTo(0, canvas.height);
          ctx.closePath();
          ctx.fill();

          // Draw the second half with the second color
          ctx.fillStyle = colors[1];
          ctx.beginPath();
          ctx.moveTo(canvas.width, 0);
          ctx.lineTo(canvas.width, canvas.height);
          ctx.lineTo(0, canvas.height);
          ctx.closePath();
          ctx.fill();

          if (colors.length === 3) {
            // Draw the third color on top
            ctx.fillStyle = colors[2];
            ctx.beginPath();
            ctx.moveTo(0, (canvas.height / 3) * 2);
            ctx.lineTo((canvas.width / 3) * 2, 0);
            ctx.lineTo(canvas.width, 0);
            ctx.lineTo(canvas.width, canvas.height / 3);
            ctx.lineTo(canvas.width / 3, canvas.height);
            ctx.lineTo(0, canvas.height);
            ctx.closePath();
            ctx.fill();
          }
        } else {
          ctx.fillStyle = '#ffffff';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
        }
      }
    }
  };

  const convertCanvasToImageFile = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], 'color_preview.png', {
            type: 'image/png',
          });

          setIsSubmitting(true);
          uploadAsset(file, { assetContext: AssetContext.Property })
            .then((asset) => {
              onAssetUpload([asset]);
              setIsSubmitting(false);
              setPopup(false);
            })
            .catch(() => {
              setIsSubmitting(false);
            });
        }
      }, 'image/png');
    }
  };

  const renderColorPickers = () => {
    const colorPickers: ReactNode[] = [];
    Object.entries(colorConfig).forEach(([key, value], i) => {
      colorPickers.push(
        <div className={'popup-colorPicker'} key={i}>
          <ColorPicker
            title={`${t('popup.color')} #${i + 1}`}
            onSelect={(hex) => {
              setColorConfig({
                ...colorConfig,
                [key]: hex,
              });
            }}
            colorHex={value}
          />
          <Button
            type={'icon'}
            width={'minimal'}
            look={'secondary-danger'}
            helperCSSClass={'popup-colorPicker-button'}
            active={!!value}
            action={() => {
              setColorConfig({
                ...colorConfig,
                [key]: undefined,
              });
            }}
          >
            <IconTrash
              className={'button-icon-tiny button-icon-danger'}
              style={
                !value ? { fill: 'var(--color-gray) !important' } : undefined
              }
            />
          </Button>
        </div>
      );
    });
    return colorPickers;
  };

  return (
    <div className="hexColorAsset">
      <div className={'inputHeader input-title'}>{t('title')}</div>
      <div className="hexColorAsset-container">
        {hasAsset ? (
          <div className={'hexColorAsset-info'}>{t('hasAsset')}</div>
        ) : (
          <>
            <div className={'hexColorAsset-colorPicker'}>
              <ColorPicker onSelect={onHexChange} colorHex={hex} />
            </div>
            <Button
              type={'icon'}
              look={'secondary'}
              width={'minimal'}
              action={() => setPopup(true)}
              helperCSSClass={'hexColorAsset-button'}
            >
              <IconSettings className={'button-icon button-icon-secondary'} />
            </Button>
          </>
        )}
      </div>
      <Popup toggled={popup} width={'30%'} close={() => setPopup(false)}>
        <div className={'popup-title'}>{t('popup.title')}</div>
        <div className={'popup-colorPickers'}>{renderColorPickers()}</div>
        <div className={'popup-preview'}>
          <div className={'inputHeader input-title'}>{t('preview')}</div>
          <canvas ref={canvasRef} width="80" height="80" />
        </div>
        <Button
          cta={t('popup.cta')}
          width={'full'}
          action={convertCanvasToImageFile}
          look={'save'}
          isLoading={isSubmitting}
          active={
            Object.values(colorConfig).filter((color) => color !== undefined)
              .length > 1
          }
        />
      </Popup>
    </div>
  );
};

export default HexColorAsset;
