import { Check, Dropdown } from '../../../../elements/selectors/Selectors';
import DropdownMenu from '../../../../elements/dropdownmenu/DropdownMenu';
import DatePicker from '../../../../elements/datepicker/DatePicker';
import DateRange from '../../../../elements/daterange/DateRange';
import Input from '../../../../elements/input/Input';
import { ReactNode } from 'react';

export type ListFilterProps = {
  title?: string;
  update?: (param: any) => void;
  options?: string[];
  optionObjects?: { name: string; id: string }[] | null;
  onClearAll?: () => void;
  defaultOptionText?: string;
  checked?: boolean;
  date?: Date;
  dateRange?: string[];
  showTimeSelect?: boolean;
  selected?: any;
  multiSelected?: string[] | null;
  value?: string | null;
  inputType?: 'text' | 'number';
  disableAutoOptionSorting?: boolean;
  renderMethod?: () => ReactNode;
  nativeDropdown?: boolean;
};

const ListFilter: React.FC<ListFilterProps> = ({
  title,
  options,
  optionObjects,
  checked,
  date,
  dateRange,
  showTimeSelect,
  update,
  onClearAll,
  selected,
  multiSelected,
  value,
  inputType,
  disableAutoOptionSorting,
  renderMethod,
  nativeDropdown,
  defaultOptionText,
}) => {
  if (renderMethod) {
    return <div className="listFilter">{renderMethod()}</div>;
  }
  if (update) {
    if (optionObjects || options) {
      if (nativeDropdown) {
        return (
          <div className="listFilter">
            <Dropdown
              title={title}
              defaultOptionText={title}
              options={options}
              optionObjects={optionObjects}
              update={(e) => {
                if (optionObjects) {
                  const id =
                    e.currentTarget.selectedOptions[0].getAttribute(
                      'data-value'
                    );
                  if (id) {
                    const option = optionObjects.find(
                      (option) => option.id === id
                    );
                    update(option);
                  }
                }
                if (options) {
                  const val = e.currentTarget.selectedOptions[0].value;
                  if (val) {
                    update(val);
                  }
                }
              }}
              selected={selected}
              disableAutoOptionSorting={disableAutoOptionSorting}
            />
          </div>
        );
      } else {
        return (
          <div className="listFilter">
            <DropdownMenu
              title={title}
              defaultOptionText={defaultOptionText}
              options={options}
              optionObjects={optionObjects}
              onSelect={(val, id) => {
                if (optionObjects) {
                  if (id) {
                    const option = optionObjects.find(
                      (option) => option.id === id
                    );
                    update(option);
                  }
                }
                if (options) {
                  if (val) {
                    update(val);
                  }
                }
              }}
              onClearAll={onClearAll}
              selected={selected}
              multiSelected={multiSelected}
              disableAutoOptionSorting={disableAutoOptionSorting}
            />
          </div>
        );
      }
    } else if (checked !== undefined) {
      return (
        <div className="listFilter">
          <div
            className="button button-tertiary listFilter-boolean"
            onClick={() => update(!checked)}
          >
            <Check checked={checked} />
            <div className="listFilter-boolean-text">{title}</div>
          </div>
        </div>
      );
    } else if (date) {
      return (
        <div className="listFilter">
          <DatePicker
            title={title}
            selected={date}
            onChange={update}
            showTimeSelect={showTimeSelect}
          />
        </div>
      );
    } else if (dateRange) {
      return (
        <div className="listFilter">
          <DateRange
            selectedDateRange={dateRange}
            update={update}
            showTimeSelect={showTimeSelect}
          />
        </div>
      );
    } else if (value) {
      return (
        <div className="listFilter">
          <Input
            title={title}
            content={value}
            update={(value) => update(value)}
            type={inputType}
          />
        </div>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default ListFilter;
