import './view.css';
import { useEffect, useState } from 'react';
import Sidebar from '../layout/sidebar/Sidebar';
import Topbar from '../layout/topbar/Topbar';
import useManufacturerConditions from '../hooks/useManufacturerConditions';
import { useUser } from '../contexts/auth/User';

import { ReactComponent as IconDashboard } from '../../assets/icon/sidebar/dashboard.svg';
import { ReactComponent as IconProducts } from '../../assets/icon/sidebar/products.svg';
import { ReactComponent as IconOrders } from '../../assets/icon/sidebar/orders.svg';
import { ReactComponent as IconShop } from '../../assets/icon/sidebar/store.svg';
import { ReactComponent as IconManufacturers } from '../../assets/icon/sidebar/group.svg';
import { ReactComponent as IconDocument } from '../../assets/icon/sidebar/invoice.svg';
import { ReactComponent as IconStats } from '../../assets/icon/sidebar/stats.svg';
import { ReactComponent as IconAssets } from '../../assets/icon/sidebar/folder.svg';
import { ReactComponent as IconShield } from '../../assets/icon/sidebar/shield.svg';
import { ReactComponent as IconLogistics } from '../../assets/icon/sidebar/logistics.svg';
import { ReactComponent as IconCard } from '../../assets/icon/sidebar/credit_card.svg';
import { ReactComponent as IconBrand } from '../../assets/icon/sidebar/brand.svg';
import { ReactComponent as IconExchange } from '../../assets/icon/sidebar/exchange.svg';
import { ReactComponent as IconHelp } from '../../assets/icon/sidebar/help.svg';
import { ReactComponent as IconIntegrations } from '../../assets/icon/sidebar/integration.svg';
import useUserTools from '../hooks/useUserTools';
import { useBlocker } from 'react-router-dom';
import NavigationBlocker from '../elements/navigationblocker/NavigationBlocker';

interface ViewProps {
  previousUrlOverride?: string;
  shouldBlock?: boolean;
}

export type NavElement = {
  title: string;
  active: boolean;
  link: string;
  icon?: (active: boolean) => JSX.Element;
  children?: NavElement[];
  underConstruction?: boolean;
  condition?: boolean;
};

const View: React.FC<ViewProps> = ({
  previousUrlOverride,
  shouldBlock,
  children,
}) => {
  const { user } = useUser();
  const { isUsingCentralWarehouse } = useManufacturerConditions();
  const { hasAnyAuthorization, hasAuthorizations } = useUserTools();
  const [mobileMenu, setMobileMenu] = useState(false);
  const currentPath = window.location.pathname;
  const firstPathEnd = currentPath.indexOf('/', 1);
  let path = '';
  if (firstPathEnd >= 0) {
    path = currentPath.substring(0, firstPathEnd);
  } else {
    path = currentPath;
  }

  let blocker = useBlocker(shouldBlock ?? false);

  useEffect(() => {
    console.log('shouldBlock has changed', shouldBlock);
    const blockNavigation = (event: BeforeUnloadEvent) => {
      console.log('trying to block navigation', shouldBlock);
      if (shouldBlock) {
        event.preventDefault();
        event.returnValue =
          'Are you sure you want to leave? Unsaved changes may be lost!';
      }
    };

    window.addEventListener('beforeunload', blockNavigation);

    return () => {
      window.removeEventListener('beforeunload', blockNavigation);
    };
  }, [shouldBlock]);

  const getFill = (active: boolean) => {
    return active ? 'var(--color-primary)' : 'var(--color-text_secondary)';
  };

  const navElements: NavElement[] = [
    {
      title: 'dashboard',
      active: false,
      link: '/',
      icon: (active) => <IconDashboard fill={getFill(active)} />,
    },
    {
      title: 'products.title',
      active: false,
      link: '/products',
      icon: (active) => <IconProducts fill={getFill(active)} />,
      condition: hasAnyAuthorization([
        'product_groups:list',
        'products:list',
        'promotions:list',
      ]),
      children: [
        {
          title: 'products.all',
          active: false,
          link: '/products',
          condition: hasAnyAuthorization([
            'product_groups:list',
            'products:list',
          ]),
        },
        {
          title: 'products.bulkEditor',
          active: false,
          link: '/productBulkEditor',
          condition:
            user?.isProductOwner ||
            !!user?.manufacturerConditions?.find(
              (c) => c.key === 'BulkProductImportAware' && c.value === 'true'
            ),
        },
        {
          title: 'products.promotions',
          active: false,
          link: '/promotions',
          condition:
            (!!user?.manufacturerConditions?.find(
              (c) => c.key === 'PromotionAware' && c.value === 'true'
            ) &&
              hasAnyAuthorization(['promotions:list'])) ||
            user?.isProductOwner,
        },
        {
          title: 'products.bundles',
          active: false,
          link: '/bundles',
          condition:
            (!!user?.manufacturerConditions?.find(
              (c) => c.key === 'ProductBundleAware' && c.value === 'true'
            ) &&
              hasAnyAuthorization(['product_bundle:list'])) ||
            user?.isProductOwner,
        },
        {
          title: 'products.productValidation',
          active: false,
          link: '/productValidation',
          condition: hasAnyAuthorization(['products:list']),
        },
      ],
    },
    {
      title: 'brands',
      active: false,
      link: '/brands',
      icon: (active) => <IconBrand fill={getFill(active)} />,
      condition: hasAnyAuthorization(['brands:list']),
    },
    {
      title: 'logistics.logistics',
      active: false,
      link: '/stocks',
      icon: (active) => <IconLogistics fill={getFill(active)} />,
      condition: hasAnyAuthorization([
        'fiege_purchase_orders:list',
        'product_stocks:list',
      ]),
      children: [
        {
          title: 'logistics.stocks',
          active: false,
          link: '/stocks',
          condition: hasAnyAuthorization(['product_stocks:list']),
        },
        {
          title: 'logistics.purchaseorders',
          active: false,
          link: '/purchaseorders',
          condition:
            (isUsingCentralWarehouse() &&
              hasAnyAuthorization(['fiege_purchase_orders:list'])) ||
            user?.isProductOwner,
        },
      ],
    },
    {
      title: 'orders.orders',
      active: false,
      link: '/orders',
      icon: (active) => <IconOrders fill={getFill(active)} />,
      condition: hasAnyAuthorization(['orders:list', 'customers:list']),
      children: [
        {
          title: 'orders.all_orders',
          active: false,
          link: '/orders',
          condition: hasAnyAuthorization(['orders:list']),
        },
        {
          title: 'orders.customers',
          active: false,
          link: '/customers',
          condition: hasAnyAuthorization(['customers:list']),
        },
      ],
    },
    {
      title: 'payments',
      active: false,
      link: '/payments',
      condition: hasAnyAuthorization(['payment_transactions:list']),
      icon: (active) => <IconCard fill={getFill(active)} />,
    },
    {
      title: 'shop',
      active: false,
      link: '/shop',
      condition: hasAnyAuthorization(['brand_stores:list']),
      icon: (active) => <IconShop fill={getFill(active)} />,
    },
    {
      title: 'assets',
      active: false,
      link: '/assets',
      condition: hasAuthorizations(['assets:list', 'asset_folders:list']),
      icon: (active) => <IconAssets fill={getFill(active)} />,
    },
    {
      title: 'integrations.title',
      active: false,
      link: '/integrations',
      condition: hasAuthorizations(['integrations_api:list']),
      icon: (active) => <IconIntegrations fill={getFill(active)} />,
      children: [
        {
          title: 'integrations.config',
          active: false,
          link: '/integrations',
          condition: hasAuthorizations(['integrations_api:list']),
        },
        {
          title: 'integrations.logs',
          active: false,
          link: '/integrationlogs',
          condition: hasAuthorizations(['integrations_api:list']),
        },
      ],
    },
    {
      title: 'dataexchanges',
      active: false,
      link: '/dataexchanges',
      condition: hasAuthorizations(['manufacturer_data_exchanges:list']),
      icon: (active) => <IconExchange fill={getFill(active)} />,
    },
    {
      title: 'help',
      active: false,
      link: '/help',
      icon: (active) => <IconHelp fill={getFill(active)} />,
    },
    {
      title: 'documents.documents',
      active: false,
      link: '/invoices',
      underConstruction: true,
      icon: (active) => <IconDocument fill={getFill(active)} />,
      children: [
        {
          title: 'documents.invoices',
          active: false,
          link: '/invoices',
          condition: user?.isProductOwner,
        },
      ],
    },
    {
      title: 'statistics',
      active: false,
      link: '/statistics',
      underConstruction: true,
      icon: (active) => <IconStats fill={getFill(active)} />,
    },
    {
      title: 'manufacturers',
      active: false,
      link: '/manufacturers',
      icon: (active) => <IconManufacturers fill={getFill(active)} />,
      condition: user?.isProductOwner,
    },
    {
      title: 'admin.title',
      active: false,
      link: '/admin/animalspecies',
      icon: (active) => <IconShield fill={getFill(active)} />,
      condition: user?.isProductOwner,
      children: [
        {
          title: 'admin.animalSpecies',
          active: false,
          link: '/admin/animalspecies',
        },
        {
          title: 'admin.categories',
          active: false,
          link: '/admin/categories',
        },
        {
          title: 'admin.productGroups',
          active: false,
          link: '/admin/productgroups',
        },
        {
          title: 'admin.productUnits',
          active: false,
          link: '/admin/productunits',
        },
        {
          title: 'admin.properties',
          active: false,
          link: '/admin/properties',
        },
        {
          title: 'admin.productGroupMappings',
          active: false,
          link: '/admin/productgroupmappings',
        },
        {
          title: 'admin.propertyValidationRules',
          active: false,
          link: '/admin/propertyvalidationrules',
        },
        {
          title: 'admin.loyalty',
          active: false,
          link: '/admin/loyalty',
        },
        {
          title: 'admin.warehouses',
          active: false,
          link: '/admin/warehouses',
        },
        {
          title: 'admin.tags',
          active: false,
          link: '/admin/tags',
        },
      ],
    },
  ];

  navElements.forEach((element) => {
    element.active = false;
    if (element.children) {
      element.children.forEach((child) => {
        child.active = false;
      });
    }
  });

  const i = navElements.findIndex((element) => element.link === path);

  if (i >= 0 && !navElements[i].children) {
    navElements[i].active = true;
  } else if (i >= 0 && navElements[i].children) {
    navElements[i].children![0].active = true;
  } else if (i < 0) {
    navElements.forEach((element) => {
      if (element.children) {
        element.children.forEach((child) => {
          if (child.link === currentPath) {
            child.active = true;
          }
        });
      }
    });
  }

  const toggleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  useEffect(() => {
    localStorage.setItem(
      'previousPage',
      localStorage.getItem('currentPage') ?? 'undefined'
    );
    localStorage.setItem(
      'currentPage',
      previousUrlOverride ?? window.location.href
    );
  }, [currentPath]);

  return (
    <>
      <Topbar toggleMobileMenu={toggleMobileMenu} />
      <div className="main">
        <Sidebar
          navElements={navElements}
          mobileMenu={mobileMenu}
          toggleMobileMenu={toggleMobileMenu}
        />
        {children}
      </div>
      <NavigationBlocker blocker={blocker} />
    </>
  );
};

export default View;
