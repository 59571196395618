import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigator, Stage } from '../../layout/stage/Stage';
import Button from '../../elements/button/Button';
import { ReactComponent as Export } from '../../../assets/icon/export.svg';
import Card, { CardSection } from '../../elements/card/Card';
import List from '../../features/list/List';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { Has } from '../../contexts/auth/Authorization';
import { LoadingContainer } from '../../elements/loading/Loading';
import { useNavigate } from 'react-router-dom';
import { SimpleManufacturerResponse } from '../../api/petcloudapi/api';
import useListControlsSearch from '../../features/list/listcontrols/hooks/useListControlsSearch';

interface MinifiedManufacturer {
  id: string;
  title: string;
  customerNumber: string;
  status: string;
  createdAt: string;
  logo?: string;
}

const Manufacturers = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.manufacturers',
  });
  const link = useNavigate();
  const { listControlSearch, query, currentItems, setOriginalItems } =
    useListControlsSearch<MinifiedManufacturer>(
      t('search'),
      [],
      ['title', 'customerNumber']
    );
  const [manufacturers, setManufacturers] = useState<
    MinifiedManufacturer[] | null
  >(null);

  const api = usePetCloudApi().manufacturersApi();

  useEffect(() => {
    if (!manufacturers) {
      api.manufacturersGetSimpleManufacturers().then((response) => {
        console.log(response.data);

        const minifiedManufacturers = response.data.map(
          (manufacturer: SimpleManufacturerResponse) => {
            return {
              id: manufacturer.id,
              logo: manufacturer.logoAsset?.uri,
              title: manufacturer.companyName,
              customerNumber: manufacturer.customerNumber,
              status: manufacturer.enabled ? 'Active' : 'Inactive',
              createdAt: manufacturer.createdAt,
            };
          }
        );
        setOriginalItems(minifiedManufacturers);
        setManufacturers(minifiedManufacturers);
      });
    }
  }, [manufacturers]);

  if (manufacturers !== null) {
    return (
      <Stage>
        <Navigator title={t('title')}>
          <Button cta={t('export')} margin="left" type="icon-text">
            <Export
              className="button-icon"
              fill="var(--color-text_secondary)"
            />
          </Button>
          <Has authorizations={['manufacturers:create']}>
            <Button
              cta={t('new')}
              margin="left"
              look="primary"
              action={() => link('/manufacturers/new')}
            ></Button>
          </Has>
        </Navigator>
        <Card bigScreenWidth="100%">
          <Has authorizations={['manufacturers:list']}>
            <CardSection>
              <List
                items={currentItems}
                linkedKey="id"
                ignore={['id']}
                dateStrings={['createdAt']}
                adjustHeightToViewport
                adjustHeightToViewportOffset={80}
                monoSpaceStrings={['customerNumber']}
                badgeKeys={['status']}
                itemImgKey={'logo'}
                queryString={query}
                queryKeys={['title', 'customerNumber']}
                tableHeadContrast
                listControls={{
                  search: listControlSearch,
                }}
              />
            </CardSection>
          </Has>
        </Card>
      </Stage>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default Manufacturers;
