import './salesoverviewelement.css';
import { SalesOverViewMode } from '../SalesOverview';
import { useTranslation } from 'react-i18next';
import useNumberFormat from '../../../../hooks/useNumberFormat';

type Ratio = {
  value: number;
  color: string;
};

interface SalesOverviewElementProps {
  value: number;
  mode: SalesOverViewMode;
  averageValue: number;
  ratio?: Ratio;
  icon: React.ReactNode;
}

const SalesOverviewElement: React.FC<SalesOverviewElementProps> = ({
  value,
  mode,
  averageValue,
  ratio,
  icon,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.salesOverview',
  });

  const { renderCurrency, renderRoundedFloat } = useNumberFormat();

  return (
    <div className="salesOverviewElement">
      {icon}
      <div className="salesOverviewElement-value">
        {mode === 'revenue' ? renderCurrency(value) : value}
      </div>
      <div className="salesOverviewElement-sub">
        {ratio ? (
          <div
            className={'salesOverviewElement-sub-ratio'}
            style={{ color: ratio.color }}
          >
            {!isNaN(ratio.value) ? renderRoundedFloat(ratio.value) : '-'}%
          </div>
        ) : null}
        {`⌀ ${
          mode === 'revenue'
            ? renderCurrency(averageValue)
            : renderRoundedFloat(averageValue)
        } ${t('avgPerDay')}`}
      </div>
    </div>
  );
};

export default SalesOverviewElement;
