import './productgroups.css';
import { useEffect, useState } from 'react';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import {
  ProductGroupResponse,
  CreateProductGroupRequest,
  AnimalSpeciesResponse,
  UpdateProductGroupRequest,
  PropertyValidationRuleResponse,
  PropertyGroupResponse,
} from '../../../api/petcloudapi/api';
import { LoadingContainer } from '../../../elements/loading/Loading';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import Button from '../../../elements/button/Button';
import { useTranslation } from 'react-i18next';
import Popup from '../../../elements/popup/Popup';
import List from '../../../features/list/List';
import { Dropdown } from '../../../elements/selectors/Selectors';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import useNotifications from '../../../hooks/useNotifications';
import ProductGroupEdit from './productgroupedit/ProductGroupEdit';
import useListRenderObjects from '../../../hooks/list/useListRenderObjects';
import useUserTools from '../../../hooks/useUserTools';
import useListControlsSearch from '../../../features/list/listcontrols/hooks/useListControlsSearch';

const newGroup: CreateProductGroupRequest = {
  name: {
    'de-DE': null,
    'en-GB': null,
  },
  description: {
    'de-DE': null,
    'en-GB': null,
  },
  identifier: '',
};

const ProductGroups = () => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.admin.productGroups',
  });
  const { pushNotification } = useNotifications();
  const api = usePetCloudApi();
  const errorHandler = useErrorHandler();
  const productGroupsApi = api.productGroupsApi();
  const animalSpeciesApi = api.animalSpeciesApi();
  const propertyValidationRulesApi = api.propertyValidationRulesApi();
  const propertyGroupsApi = api.propertyGroupsApi();
  const { renderAnimalSpecies, renderArrayCount } = useListRenderObjects();
  const { excludeUnlockedOptions } = useUserTools();
  const { listControlSearch, currentItems, setOriginalItems, query } =
    useListControlsSearch<ProductGroupResponse>(
      t('search'),
      [],
      ['identifier', 'name.de-DE', 'name.en-GB']
    );

  const [productGroups, setProductGroups] = useState<
    ProductGroupResponse[] | null
  >(null);
  const [selectedProductGroups, setSelectedProductGroups] = useState<
    ProductGroupResponse[]
  >([]);
  const [newProductGroup, setNewProductGroup] =
    useState<CreateProductGroupRequest>(newGroup);
  const [dangerPopup, setDangerPopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newGroupPopup, setNewGroupPopup] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState<TranslatedStringIndex>(
    i18n.language as TranslatedStringIndex
  );
  const [groupToEdit, setGroupToEdit] = useState<
    UpdateProductGroupRequest | null | undefined
  >(undefined);
  const [availableAnimalSpecies, setAvailableAnimalSpecies] = useState<
    AnimalSpeciesResponse[] | null
  >(null);
  const [
    availablePropertyValidationRules,
    setAvailablePropertyValidationRules,
  ] = useState<PropertyValidationRuleResponse[] | null>(null);
  const [availablePropertyGroups, setAvailablePropertyGroups] = useState<
    PropertyGroupResponse[] | null
  >(null);

  useEffect(() => {
    getProductGroups();
    getAvailableAnimalSpecies();
    getAvailablePropertyValidationRules();
    getAvailablePropertyGroups();
  }, []);

  const getAvailablePropertyGroups = () => {
    setAvailablePropertyGroups(null);
    propertyGroupsApi
      .propertyGroupsGetPropertyGroups(
        undefined,
        undefined,
        excludeUnlockedOptions()
      )
      .then((response) => {
        console.log(response.data);
        setAvailablePropertyGroups(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getProductGroups = () => {
    productGroupsApi
      .productGroupsGetProductGroups()
      .then((response) => {
        console.log(response.data);
        setProductGroups([...response.data]);
        setOriginalItems(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getAvailableAnimalSpecies = () => {
    animalSpeciesApi
      .animalSpeciesGetAnimalSpecies()
      .then((response) => {
        console.log(response);
        setAvailableAnimalSpecies(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getAvailablePropertyValidationRules = () => {
    propertyValidationRulesApi
      .propertyValidationRulesGetPropertyValidationRules()
      .then((response) => {
        console.log(response);
        setAvailablePropertyValidationRules(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const selectGroup = (item: ProductGroupResponse) => {
    const update = selectedProductGroups;
    const i = update.findIndex((group) => group.id === item.id);
    if (i !== -1) {
      update.splice(i, 1);
    } else {
      update.push(item);
    }
    setSelectedProductGroups([...update]);
  };

  const selectAllGroups = () => {
    if (
      productGroups &&
      selectedProductGroups.length !== productGroups.length
    ) {
      setSelectedProductGroups([...productGroups]);
    } else {
      setSelectedProductGroups([]);
    }
  };

  const deleteSelectedProductGroups = () => {
    setIsSubmitting(true);
    const promises = selectedProductGroups.map((group) => {
      return deleteGroup(group.id);
    });

    Promise.all(promises)
      .then(() => {
        pushNotification(t('notifications.delete_successful'));
        setDangerPopup(false);
        setIsSubmitting(false);
        setSelectedProductGroups([]);
        getProductGroups();
      })
      .catch(() => {
        pushNotification(t('notifications.delete_failed'));
        setDangerPopup(false);
        setIsSubmitting(false);
        setSelectedProductGroups([]);
        getProductGroups();
      });
  };

  const deleteGroup = (id: string) => {
    return new Promise((resolve, reject) => {
      productGroupsApi
        .productGroupsDeleteProductGroup(id)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          reject(error);
        });
    });
  };

  const submitNewProductGroup = () => {
    setIsSubmitting(true);
    productGroupsApi
      .productGroupsCreateProductGroup(newProductGroup)
      .then((response) => {
        console.log(response);
        pushNotification(t('notifications.create_successful'));
        setIsSubmitting(false);
        setNewProductGroup(newGroup);
        getProductGroups();
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setIsSubmitting(false);
      });
  };

  const getGroupToEdit = (id: string) => {
    setGroupToEdit(null);
    productGroupsApi
      .productGroupsGetProductGroupById(id)
      .then((response) => {
        console.log(response);
        setGroupToEdit({
          ...response.data,
          animalSpeciesIds: response.data.animalSpecies?.map((x) => x.id),
          propertyGroupIds: response.data.propertyGroups?.map(
            (x) => x.id ?? 'productGroup missing id'
          ),
          propertyValidationRuleIds:
            response.data.additionalPropertyValidationRules?.map((x) => x.id),
        });
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
        setGroupToEdit(undefined);
      });
  };

  const submitEditedGroup = () => {
    if (groupToEdit) {
      setIsSubmitting(true);
      productGroupsApi
        .productGroupsUpdateProductGroup(
          (groupToEdit as ProductGroupResponse).id,
          groupToEdit
        )
        .then((response) => {
          console.log(response);
          pushNotification(t('notifications.edit_successful'));
          setIsSubmitting(false);
          getProductGroups();
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsSubmitting(false);
        });
    }
  };

  const getResponsesFromIds = (
    responses: any[],
    ids: string[] | null | undefined
  ) => {
    if (ids && ids.length > 0) {
      return responses.filter((r) => ids.includes(r.id));
    } else {
      return [];
    }
  };

  if (
    productGroups &&
    availableAnimalSpecies &&
    availablePropertyValidationRules &&
    availablePropertyGroups
  ) {
    return (
      <div className="productGroups">
        <List
          items={currentItems}
          selectedItems={selectedProductGroups}
          onSelect={selectGroup}
          onSelectAll={selectAllGroups}
          ignore={['updatedAt', 'id']}
          dateStrings={['createdAt']}
          translatedStrings={['name', 'description']}
          selectedLocale={selectedLocale}
          onRowClick={(group) => {
            getGroupToEdit(group.id);
          }}
          renderObjects={[
            {
              key: 'animalSpecies',
              renderMethod: renderAnimalSpecies,
            },
            {
              key: 'additionalPropertyValidationRules',
              renderMethod: renderArrayCount,
            },
            {
              key: 'propertyGroups',
              renderMethod: renderArrayCount,
            },
          ]}
          sortValueFunctions={{
            name: (group) =>
              group.name[i18n.language as TranslatedStringIndex].toLowerCase(),
            description: (group) =>
              group.description[
                i18n.language as TranslatedStringIndex
              ].toLowerCase(),
          }}
          actions={[
            {
              cta: 'edit',
              look: 'blue',
              action: (group: ProductGroupResponse) => {
                getGroupToEdit(group.id);
              },
            },
            {
              cta: 'delete',
              look: 'danger',
              action: (item: ProductGroupResponse) => {
                setSelectedProductGroups([item]);
                setDangerPopup(true);
              },
            },
          ]}
          adjustHeightToViewport
          adjustHeightToViewportOffset={120}
          tableHeadContrast
          queryKeys={['name', 'identifier']}
          queryString={query}
          listControls={{
            search: listControlSearch,
            children: [
              <>
                <div className="listFilters-filter">
                  <Dropdown
                    options={['de-DE', 'en-GB']}
                    selected={selectedLocale}
                    update={(e) => {
                      const val =
                        e.target.selectedOptions[0].getAttribute('data-value');
                      if (val) {
                        setSelectedLocale(val as TranslatedStringIndex);
                      }
                    }}
                  />
                </div>
                {selectedProductGroups.length > 0 ? (
                  <div className="listFilters-filter">
                    <Button
                      cta={t('listActions.delete')}
                      width={'minimal'}
                      look="secondary-danger"
                      action={() => setDangerPopup(true)}
                      margin="right"
                    />
                  </div>
                ) : null}
              </>,
            ],
          }}
        />
        <div className="global-cardActions-postBorder">
          <Button
            width="minimal"
            look={'secondary'}
            cta={t('new')}
            action={() => setNewGroupPopup(true)}
          />
        </div>
        <Popup
          width="30%"
          toggled={dangerPopup}
          close={() => setDangerPopup(false)}
        >
          <div className="popup-title">{t('dangerPopup.title')}</div>
          <div className="productGroups-dangerPopup-message">
            {t('dangerPopup.message')}
          </div>
          <Button
            width="full"
            look="danger"
            cta={t('dangerPopup.cta')}
            action={deleteSelectedProductGroups}
            isLoading={isSubmitting}
          />
        </Popup>
        <Popup
          width="40%"
          toggled={newGroupPopup}
          close={() => setNewGroupPopup(false)}
        >
          <div className="popup-title">{t('newPopup.title')}</div>
          <ProductGroupEdit
            group={newProductGroup}
            setProductGroup={setNewProductGroup}
            availableAnimalSpecies={availableAnimalSpecies}
            animalSpecies={getResponsesFromIds(
              availableAnimalSpecies,
              newProductGroup.animalSpeciesIds
            )}
            availablePropertyValidationRules={availablePropertyValidationRules}
            refreshAvailablePropertyGroups={getAvailablePropertyGroups}
            refreshAvailablePropertyValidationRules={
              getAvailablePropertyValidationRules
            }
            availablePropertyGroups={availablePropertyGroups}
          />
          <div className="global-cardActions productGroups-actions">
            <Button
              width="minimal"
              look="save"
              cta={t('newPopup.submit')}
              action={submitNewProductGroup}
              isLoading={isSubmitting}
            />
          </div>
        </Popup>
        {groupToEdit !== undefined ? (
          <Popup
            width="40%"
            toggled={true}
            close={() => setGroupToEdit(undefined)}
          >
            <div className="popup-title">{t('editPopup.title')}</div>
            {groupToEdit ? (
              <>
                <ProductGroupEdit
                  group={groupToEdit}
                  setProductGroup={(
                    group: UpdateProductGroupRequest | CreateProductGroupRequest
                  ) => {
                    const animalSpecies = availableAnimalSpecies.filter((s) =>
                      group.animalSpeciesIds?.includes(s.id)
                    );
                    const propertyGroups = availablePropertyGroups.filter((g) =>
                      group.propertyGroupIds?.includes(g.id)
                    );
                    setGroupToEdit({
                      ...group,
                      animalSpecies: animalSpecies,
                      propertyGroups: propertyGroups,
                    } as unknown as ProductGroupResponse);
                  }}
                  availableAnimalSpecies={availableAnimalSpecies}
                  availablePropertyValidationRules={
                    availablePropertyValidationRules
                  }
                  refreshAvailablePropertyValidationRules={
                    getAvailablePropertyValidationRules
                  }
                  refreshAvailablePropertyGroups={getAvailablePropertyGroups}
                  animalSpecies={
                    (groupToEdit as ProductGroupResponse).animalSpecies
                  }
                  availablePropertyGroups={availablePropertyGroups}
                />
                <div className="global-cardActions productGroups-actions">
                  <Button
                    width="minimal"
                    look="save"
                    cta={t('editPopup.cta')}
                    action={submitEditedGroup}
                    isLoading={isSubmitting}
                  />
                </div>
              </>
            ) : (
              <LoadingContainer />
            )}
          </Popup>
        ) : null}
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default ProductGroups;
